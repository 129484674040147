import { useEffect } from "react"


export const useSearchShortcut = (onSave: () => void) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault()
        onSave()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [onSave])
}

type ShortcutInfoProps = {
  modifier?: 'cmd',
  keyButton: string
  info: string
}
export const ShortcutInfo: React.FC<ShortcutInfoProps> = ({ modifier, keyButton, info }) => {
  const isMac = navigator.platform.toUpperCase().includes('MAC')
  const modifierName = () => {
    if (!modifier) return
    if (modifier === 'cmd' && isMac) {
      return <div>⌘</div>
    }
    return <div>{modifier}</div>
  }
  return <div className='flex gap-1 w-fit items-center text-gray-500 text-xs'>
    <div className="flex gap-0.5 ">
      <div>{modifierName()}</div>
      <div>{keyButton}</div>
    </div>
    <div>{info}</div>
  </div>
}