import { Dispatch, HTMLAttributes, SetStateAction, useMemo } from 'react'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

import { routes, useParams } from '@redwoodjs/router'

import { XCircleIcon, ChevronDownIcon, ChevronRightIcon, ExclamationTriangleIcon, PlusIcon } from '@heroicons/react/20/solid'
import { ScaleIcon } from '@heroicons/react/16/solid'
import { groupBy } from 'src/lib/util'

import { AggregationNode, Aggregator } from 'src/components/PartCell/aggregation';
import { StatusDot } from '../LifecycleStatus/LifecycleStatus'
import { useRequiredContext } from 'src/lib/appContext'

import type {
  PartHierarchy,
  PartTreeNodeFragment,
} from 'types/graphql'

import { validVersion } from 'src/lib/version'

import { AppLink } from 'src/lib/routing'
import { useState, createContext, useContext } from 'react'
import { SwatchIcon } from '@heroicons/react/24/outline';
import padEnd from 'lodash.padend'
import * as Tooltip from "src/components/ToolTip"
import { letF } from 'api/src/shared/functional'
import { findPartChildren } from 'api/src/lib/bom'

import type {
  HierarchyController,
  ControllerTreeNode,
  SubjectPart,
  EditableAggregator
} from './hierarchyController'

import {
  HierarchyControllerContext
} from './hierarchyController'

export {
  HierarchyControllerContext,
  useHierarchyControllerFactory,
} from './hierarchyController'

export type {
  RootInput,
  HierarchyController,
  HierarchyControllerInput,
  ControllerTreeNode,
  SubjectPart,
  PartHierarchyHeaderColumn,
  ActivatableAggregator,
  EditableAggregator
} from './hierarchyController'

type PartHierarchyExplorerProps = {
  hierarchyController: HierarchyController
  withCreateAggSpacer?: boolean
  hasHorizontalScroll?: boolean
}

const BranchDisabledContext = createContext<boolean>(false)

type PartHierarchyContainerProps = {
  headerConfig?: Parameters<typeof PartHierarchyHeader>[0]
  alignX?: 'header' | 'children'
  controller: HierarchyController
}
export const HierarchyContainer = ({
  alignX = 'header',
  headerConfig,
  controller,
}: PartHierarchyContainerProps) => {
  return (
    <div className='flex flex-col gap-4'>
        <div className={`${alignX === 'children' ? '' : ''}`}>
          <PartHierarchyHeader hierarchyController={controller} {...headerConfig} />
        </div>
      <div className={`${alignX === 'header' ? '-mx-1' : ''} flex flex-col`}>
        <PartHierarchy hierarchyController={controller} />
      </div>
    </div>
  )
}

const nameHeaderWidth = '350px' as const
const nameHeaderWidthTailwind = 'min-w-[350px]' as const
const stickyNameStyles = {
  minWidth: nameHeaderWidth,
}
const leftSticky = {
  position: 'sticky',
  left: 0,
  zIndex: 22,
  overflow: 'visible',
  height: '35px',
  alignItems: 'center',
  ...stickyNameStyles
} as const

const PartHierarchy: React.FC<PartHierarchyExplorerProps> = ({
  hierarchyController,
  withCreateAggSpacer,
  hasHorizontalScroll,
}) => {
  const { roots } = hierarchyController
  const rootComponents = roots.map((root, i) => {
    const { tree } = root
    const rootNode = tree.find(n => n.isTreeRoot)!
    return <HierarchyControllerContext.Provider value={{ hierarchyController, root }} key={i}>
      <PartHierarchyNode node={rootNode} depth={0} withCreateAggSpacer={withCreateAggSpacer} hasHorizontalScroll={hasHorizontalScroll} />
    </HierarchyControllerContext.Provider>
  })
  return <>
    {rootComponents}
  </>
}

type PartHierarchyHeaderProps = {
  selectedAggIndex?: number
  selectedStyle?: string
  onAggregationHeaderClicked?: (agg: EditableAggregator) => void
  onCreateLocalAggregator?: () => void
  hasHorizontalScroll?: boolean
  hierarchyController: HierarchyController
}

export const PartHierarchyHeader: React.FC<PartHierarchyHeaderProps> = ({
  selectedAggIndex,
  selectedStyle = '',
  onAggregationHeaderClicked,
  onCreateLocalAggregator,
  hasHorizontalScroll,
  hierarchyController
}) => {
  const { columns, aggregators, fullScreen } = hierarchyController
  const stickyName = hierarchyController?.stickyName
  const shownAggs = fullScreen ? aggregators : []

  //not working
  const getTargetType = (a: Aggregator) => {
    const currencySetting = hierarchyController.targetCurrency
    return currencySetting?.type === a.targetType.type ? currencySetting : a.targetType
  }

  const aggClassName = (agg: EditableAggregator) =>
    twMerge(
      classNames(`
          group shrink-0 p-2 -my-2 ml-[9px] -mr-[9px] border border-t-0 border-white w-[150px] text-center
          whitespace-nowrap overflow-hidden text-ellipsis hover:overflow-visible
          hover:z-10 hover:bg-white
        `,
        {
          [selectedStyle]: agg.index === selectedAggIndex,
          'italic': agg.isDirty
        })
    )

  const AggTab = onAggregationHeaderClicked ? 'button' : 'div'

  const paddingRight = onCreateLocalAggregator ? 'pr-2' : 'pr-8'
  const rightBorder = hasHorizontalScroll ? 'border-r-2' : ''
  const stickyClass = stickyName ? `h-[45px] -mt-[17px] border-t-[1px] ${rightBorder}` : ''

  return <div className={`flex text-xs ${paddingRight}`}>
    <div className='flex-1 flex items-center' style={{
      ...leftSticky,
      height: '20px',
    }}>
      <div className={`pl-10 ${nameHeaderWidthTailwind} bg-white flex items-center ${stickyClass}`}>
        Part Number / Name
      </div>
    </div>
    <div className='flex gap-3 items-center'>
      {columns.map(column => {
        return <div
          title={column.name}
          className='shrink-0 overflow-hidden text-ellipsis whitespace-nowrap h-[17px]'
          style={{
            width: column.width, textAlign: column.align || 'left',
          }}
          key={`${column.type}.${column.key}`}>{column.name}</div>
      })}
      {shownAggs && shownAggs.length > 0 &&
        shownAggs.map((a, idx) => letF(getTargetType(a), targetType => {
          // I can't figure out why this should
          // be editable when shownAggs are ActiveAggregators??
          const agg = a as EditableAggregator
          return <AggTab
            key={idx}
            className={aggClassName(a)}
            type={onAggregationHeaderClicked ? 'button' : undefined}
            onClick={() => onAggregationHeaderClicked?.(a)}
          >
            <span className={`p-2 -m-2 bg-inherit`}>
              <ScaleIcon className='w-4 inline -mt-1' />{' '}
              {a.name} {(targetType.type === "Number" || targetType.type === "Boolean") ? null : `(${targetType.unit})`} {agg.isDirty && '*'}
            </span>
          </AggTab>
      }))
      }
      {onCreateLocalAggregator &&
        <Tooltip.Container>
          <Tooltip.Message position='leftHorizontal' className='right-8'>
            Add New Aggregation
          </Tooltip.Message>
          <button className='flex w-5 h-5 justify-center items-center ml-3 mr-4 hover:bg-gray-200 rounded' type='button' onClick={onCreateLocalAggregator}>
            <PlusIcon className='w-4 h-4' />
          </button>
        </Tooltip.Container>
      }
    </div>
  </div>
}

type Padding = {
  partNumber: number
}

const quantityAndUnit = (quantity?: number, units?: string) => {
  if (typeof quantity === 'undefined') return '-'
  units = units || 'each'
  const unit = (['each', 'units']).includes(units) ? '' : ` ${units}`
  return `${quantity}${unit}`
}

type GroupNode = { groupId: string, children: ControllerTreeNode[], hierarchy: string }

type PartHierarchyNodeProps = {
  padding?: Padding
  node: ControllerTreeNode | GroupNode
  parentKey?: string
  depth: number
  withCreateAggSpacer?: boolean
  hasHorizontalScroll?: boolean
}
const PartHierarchyNode: React.FC<PartHierarchyNodeProps> = ({
  node,
  padding,
  parentKey,
  depth,
  withCreateAggSpacer,
  hasHorizontalScroll,
}) => {
  const {
    root: {
      tree,
      subjectParts,
      selectedNodes,
      setSelectedNodes,
      aggregations,
      initiallyExpandedNodes,
    },
    hierarchyController: {
      columns,
      selectable,
      aggregators,
      usageContext,
      fullScreen,
      stickyName,
    }
  } = useRequiredContext(HierarchyControllerContext)

  const shownAggs = fullScreen ? aggregators : []

  const branchDisabled = useContext(BranchDisabledContext)

  const orgId = useParams().orgId!
  const isGroup = 'groupId' in node

  const groupNode = isGroup ? node as GroupNode : null
  const partNode = !isGroup ? node as ControllerTreeNode : null

  const hasAggregations = (shownAggs?.length || 0) > 0
  //const [expanded, setExpanded] = useState(true) //TODO: remove this

  const [expanded, setExpanded] = useState(
    initiallyExpandedNodes === 'all' ?
      true :
      initiallyExpandedNodes.includes(isGroup ? node.groupId : node.hierarchy)
  )

  const isRoot = partNode?.isTreeRoot

  // sort by part number by default
  const children = findPartChildren(tree, node.hierarchy).sort((childA, childB) => {
    if (childA.part.partNumber < childB.part.partNumber) { return -1; }
    if (childA.part.partNumber > childB.part.partNumber) { return 1; }
    return 0
  })

  const nodeProps = subjectParts?.find(p => p.hierarchy === node.hierarchy)

  const renderChildren = !nodeProps?.hideChildren
  const expandable = (isGroup || Boolean(renderChildren && children.length))
  const iconClass = 'h-5 w-5 mx-1'

  const toggleClass = classNames(
    twMerge(
      'h-[35px] flex items-center bg-white box-content',
      !(isRoot && stickyName) && 'border-t',
      nodeProps?.className,
    ),
    {
      'cursor-pointer': expandable
    }
  )

  const toggleExpand = () => {
    if (expandable) setExpanded(!expanded)
  }

  const expandButton = () => {
    const icon = expanded ?
      <ChevronDownIcon data-testid='collapse-hierarchy' className={iconClass} /> :
      <ChevronRightIcon data-testid='expand-hierarchy' className={iconClass} />

    return <div className=' text-gray-500'>
      {expandable ? icon : <div className={iconClass} />}
    </div>
  }

  const checked = (isGroup && node.children.some(c => selectedNodes.includes(c.hierarchy))) || selectedNodes.includes(node.hierarchy)
  const disableChildren = isGroup ? (branchDisabled) : (branchDisabled || (!isRoot && !checked));

  const selectBox = () => {
    if (!selectable || isRoot) return null
    return <div className='flex items-center cursor-pointer'>
      <input
        onClick={e => e.stopPropagation()}
        id="hierarchy-node-toggle"
        data-testid={`hierarchy-node-checkbox-${partNode?.part?.partNumber || groupNode?.groupId}`}
        name="hierarchy-node"
        type="checkbox"
        onChange={e => {
          let newNodes: typeof selectedNodes
          if (groupNode) {
            const children = groupNode.children.map(c => c.hierarchy)
            const decendantFilter = children.map(c => c + '.')
            const allDecendants = tree
              .filter(h => decendantFilter.some(d => h.hierarchy.startsWith(d)))
              .map(d => d.hierarchy)

            if (e.currentTarget.checked) {
              newNodes = Array.from(new Set([...selectedNodes, ...children, ...allDecendants]))
            }
            else {
              const dSet = new Set(allDecendants)
              newNodes = selectedNodes.filter(n => !children.includes(n) && !dSet.has(n))
            }
          }
          else {
            if (e.currentTarget.checked) {
              if (!expanded) {
                //when node is collapsed, include children automatically
                const allDecendants = tree
                  .filter(h => h.hierarchy.startsWith(node.hierarchy + '.'))
                  .map(d => d.hierarchy)
                newNodes = Array.from(new Set([...selectedNodes, node.hierarchy, ...allDecendants]))
              }
              else {
                newNodes = [...selectedNodes, node.hierarchy]
              }
            }
            else {
              const dSet = new Set(tree
                .filter(h => h.hierarchy.startsWith(node.hierarchy + '.'))
                .map(d => d.hierarchy))
              newNodes = selectedNodes.filter(n => n !== node.hierarchy && !dSet.has(n))
            }
          }

          setSelectedNodes(newNodes)
        }}
        checked={checked}
        disabled={branchDisabled}
        aria-describedby="hierarchy-node-checkbox"
        className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-600 cursor-pointer"
      />
    </div>
  }

  const ControlBox = () => {
    const branchDisabled = useContext(BranchDisabledContext)
    const allDecendants = tree
      .filter(h => h.hierarchy.startsWith(node.hierarchy + '.'))
      .map(d => d.hierarchy)
    return <div className='flex items-center cursor-pointer'>
      <input
        onClick={e => e.stopPropagation()}
        name="dependencyControl"
        type="checkbox"
        onChange={e => {
          setSelectedNodes(
            e.target.checked ?
              Array.from(new Set([...selectedNodes, ...allDecendants])) :
              selectedNodes.filter(n => !allDecendants.includes(n))
          )
        }}
        checked={allDecendants.every(d => selectedNodes.includes(d))}
        disabled={branchDisabled}
        aria-describedby="select-all-children"
        className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-600 cursor-pointer"
      />
    </div>
  }

  const AggNode = ({ agg, values }: { agg: Aggregator, values?: AggregationNode['values'] }) => {
    const formatter = (n: number) => agg.targetType.type === 'Price' ?
      n.toLocaleString("en", { minimumFractionDigits: 2 }) : n

    const inner = () => {
      if (agg.reducer === 'And') {
        return String(values?.[agg.name]?.[0] ?? '-')
      }

      if (!values?.[agg.name]) return '-'
      const [min, max, note] = values[agg.name]!
      return <div className='text-right ' style={{ width: 150, textAlign: 'right' }}>
        {note?.type === 'failed' ?
          <Tooltip.Container className='relative'>
            <XCircleIcon className='w-4 inline' />
            <Tooltip.Message className="bottom-10" position='left'>
              There was an error calulating this value
            </Tooltip.Message>
          </Tooltip.Container> :
          note?.type === 'success' ?
            <Tooltip.Container className='relative'>
              {min === max ?
                formatter(min) : `${formatter(min)} - ${formatter(max)}`
              }*
              <Tooltip.Message className="bottom-10 z-50" position='left'>
                This value includes conversions from the following units: [{Array.from(note.from).join(", ")}]
              </Tooltip.Message>
            </Tooltip.Container> :
            min === max ?
              formatter(min) : `${formatter(min)} - ${formatter(max)}`
        }
      </div>
    }
    return <div className='text-right ' style={{ width: 150, textAlign: 'right' }}>
      {inner()}
    </div>
  }

  const indentationLevel: HTMLAttributes<HTMLElement>['style'] = {
    paddingLeft: `${depth * 18}px`
  }
  const thickIndentationLevel: HTMLAttributes<HTMLElement>['style'] = {
    paddingLeft: `${38 + (depth * 18)}px`
  }

  const rightBorder = hasHorizontalScroll ? 'border-r-2' : ''

  if (isGroup) {
    const aggValues = aggregations?.getGroupNode(parentKey!, node.groupId)?.values
    const childPadding = node.children.reduce((padding, child) => {
      return {
        partNumber: Math.max(child.part.partNumber.length, padding.partNumber),
      }
    }, { partNumber: 0 } as { partNumber: number })
    return <div key={node.hierarchy} className='text-sm'>
      <div onClick={toggleExpand} className={toggleClass}>
        <div className={`gap-3 flex bg-inherit grow pr-2 ${stickyName ? rightBorder : ''}`} style={{
          ...(leftSticky),
          ...indentationLevel,
          ...(stickyName ? stickyNameStyles : undefined),
        }}>
          {expandButton()}
          {selectBox()}
          <div className='flex gap-2'>
            <SwatchIcon className='w-4' />
            <div className='flex-1'>
              <div>{node.groupId}</div>
            </div>
          </div>
        </div>
        <div className='flex flex-row justify-end gap-3'>
          {(columns).map(f => <div style={{ width: f.width }} className='shrink-0' key={f.name}></div>)}
          {(hasAggregations && aggValues) && (shownAggs!).map((a, idx) =>
            <AggNode key={idx} values={aggValues} agg={a} />)}
          <CreateAggSpacer show={withCreateAggSpacer} />
        </div>
        {/* Alignment for status dot */}
        <div className='flex items-center justify-center w-8  '></div>
      </div>
      {expanded && <div className='flex'>
        <div className='border-gray-100 mt-1 mb-4'></div>
        <div className='flex flex-col flex-1'>
          <BranchDisabledContext.Provider value={disableChildren}>
            {node.children.map(c =>
              <PartHierarchyNode depth={depth + 1} key={c.hierarchy} node={c} padding={childPadding}
                withCreateAggSpacer={withCreateAggSpacer} hasHorizontalScroll={hasHorizontalScroll} />
            )}
          </BranchDisabledContext.Provider>
        </div>
      </div>
      }
    </div>
  }
  else {
    const SPACE = '\u00A0'

    const fields = {
      quantity: quantityAndUnit(node.parentToNodeDependency?.quantity, node.parentToNodeDependency?.units), //String(node.dependency?.quantity ?? '-'),
      referenceDesignator: String(node.parentToNodeDependency?.referenceDesignator || '-'),
      version: node.versionDisplay,
      category: node.part.proto.category.name
    }
    const columnValues = columns.map(c => {
      if (c.type === 'field') {
        return {
          ...c,
          value: fields[c.key as keyof typeof fields]
        }
      }
    })

    const childPadding = children.reduce((padding, child) => {
      return {
        partNumber: Math.max(child.part.partNumber.length, padding.partNumber),
      }
    }, { partNumber: 0 } as { partNumber: number })

    const childrenWithGroups =
      [
        ...children.filter(c => !c.parentToNodeDependency?.groupId) as (ControllerTreeNode & { groupId?: undefined })[],
        ...Object.entries(
          groupBy(
            children.filter(c => c.parentToNodeDependency?.groupId), c => c.parentToNodeDependency?.groupId
          )
        ).map(([groupId, group]) => ({
          groupId,
          children: group!,
          hierarchy: ''
        })),
      ]

    const subParts = (expanded && renderChildren && childrenWithGroups.length) ?
      <div className='flex'>
        <div className='flex flex-col flex-1'>
          <BranchDisabledContext.Provider value={disableChildren}>
            {selectable &&
              <div className='w-full border-t flex'>
                <div className={`h-[35px] px-3 flex border-gray-200 items-center gap-3 bg-white ${stickyName ? `grow ${rightBorder} ${nameHeaderWidthTailwind}` : ''}`} style={{
                  ...leftSticky,
                  ...thickIndentationLevel,
                  height: '35px', //not sure why this is necessary to get the rows to line up
                }} >
                  <div className='w-4 -mx-1' />
                  <ControlBox />
                  Select All Children
                </div>
                <div className='bg-green-20 shrink-0 flex gap-3' /*make row the correct width*/ >
                  {(columns).map(f =>
                    <div style={{ width: f!.width }} className='shrink-0' key={f.name} />)}
                  {hasAggregations && shownAggs!.map((_, idx) =>
                    <div className='shrink-0 w-[150px]' key={idx} />)}
                  <CreateAggSpacer show={'full'} />
                </div>
              </div>
            }
            {childrenWithGroups
              .map(c =>
                <PartHierarchyNode depth={depth + 1} key={c.groupId ?? c.hierarchy} node={c} padding={childPadding}
                  withCreateAggSpacer={withCreateAggSpacer} hasHorizontalScroll={hasHorizontalScroll}
                  parentKey={node.hierarchy} />
              )}
          </BranchDisabledContext.Provider>
        </div>
      </div>
      : null

    const partNumberPadding = padding?.partNumber ? padding.partNumber + 1 : undefined
    const partNumber = padEnd(`#${node.part.partNumber}`, partNumberPadding, SPACE)

    const message = nodeProps?.message || null

    const aggValues = aggregations?.getPartNode(node.hierarchy)?.values

    const linkClass = 'underline text-blue-700 font-sans'
    const nodeInChangeOrder = Boolean(usageContext?.type === 'ChangeOrder' &&
      usageContext.changeOrder.includedParts.find(p =>
        p.partNumber === node.part.partNumber &&
        p.version === node.part.version)
    )
    // Hack because the version summary component changes the version string
    // to illustrate a version change
    const versionLink = nodeInChangeOrder ?
      <a href={`#part-change-${node.part.partNumber}`} className={linkClass}>
        {node.part.name}
      </a>
      : letF(validVersion(node.part.version as string) ?
          routes.partWithVersion({ orgId, partNumber: node.part.partNumber, version: node.part.version as string }) :
          routes.part({ orgId, partNumber: node.part.partNumber }), link =>
        <AppLink className={linkClass} to={link}>{node.part.name}</AppLink>
      )

    return <div key={node.hierarchy} className='text-sm'>
      <div onClick={toggleExpand} className={toggleClass}>
        <div className={`flex-1 gap-3 flex bg-inherit font-mono pr-2 ${stickyName ? rightBorder : ''}`} style={{
          ...leftSticky,
          ...indentationLevel,
          ...(stickyName ? stickyNameStyles : undefined),
        }}>
          {expandButton()}
          {selectBox()}
          <div className='font-medium'>{partNumber}</div>
          <div className='relative flex-1 bg-inherit flex items-center'>
            <div className='absolute left-0 right-0 bg-inherit h-8 flex items-center overflow-hidden text-ellipsis whitespace-nowrap backdrop-brightness-100 hover:pr-2 hover:overflow-visible hover:whitespace-normal hover:z-20 hover:w-max'>
              {versionLink}
            </div>
          </div>
        </div>
        <div className='flex flex-row justify-end gap-3'>
          {
            (columnValues)
              .map(f => {
                return <div
                  style={{ width: f!.width }}
                  key={`${f!.type}.${f!.key}`}
                  className='group shrink-0 text-right whitespace-nowrap overflow-hidden text-ellipsis hover:overflow-visible hover:z-10'>
                  <div className='group-hover:bg-inherit px-2 -mx-2 flex items-center' style={{ justifyContent: alignToFlexAlign(f?.align) }}>
                    <div>{f!.value}</div>{f?.key === 'version' && message}
                  </div>
                </div>
              })
          }
          {(hasAggregations) && (shownAggs!).map((a, idx) => <AggNode key={idx} values={aggValues} agg={a} />)}
          <CreateAggSpacer show={withCreateAggSpacer} />
        </div>

        <div className='flex items-center justify-center w-8 shrink-0'>
          <StatusDot lifeCycle={node.part.lifeCycle} size='sm' alignTooltip='leftHorizontal' />
        </div>
      </div>
      {subParts}
    </div>
  }
}

export default PartHierarchy


export const addVersionWarnings = (tree: PartTreeNodeFragment[], existingSubjectParts?: SubjectPart[]) => {
  const outOfDateRefsByLevel = tree.reduce((byLevel, n) => {
    if (!validVersion(n.part.version as string)) return byLevel
    if (!validVersion(n.part.proto.currentVersionString)) return byLevel
    if (n.part.version === n.part.proto.currentVersionString) return byLevel

    return {
      ...byLevel,
      [n.hierarchy]: true as const
    }
  }, {} as Record<string, true>)

  const outOfDateParentsByLevel = Object.keys(outOfDateRefsByLevel)
    .reduce((parentsByLevel, level) => {
      const levelParts = level.split(/\./g)
      return {
        ...parentsByLevel,
        [levelParts.slice(0, -1).join('.')]: true as const
      }
    }, {} as Record<string, true>)

  const outOfDateAncestorsByLevel = Object.keys(outOfDateRefsByLevel)
    .reduce((ancestorsByLevel, level) => {
      const levelParts = level.split(/\./g)
      const nextAncestors = levelParts.reduce(({ levelAcc, ancestorsByLevel }, level) => {
        const nextLevel = [...levelAcc, level]
        return {
          ancestorsByLevel: {
            ...ancestorsByLevel,
            [nextLevel.join('.')]: true as const
          },
          levelAcc: nextLevel
        }
      }, { ancestorsByLevel, levelAcc: [] as string[] })
      return nextAncestors.ancestorsByLevel
    }, {} as Record<string, true>)

  const subjectParts = [...tree].map(n => {
    const existingSubject = (existingSubjectParts?.find(s => s.hierarchy === n.hierarchy))

    const partRefOutOfDate = outOfDateRefsByLevel[n.hierarchy]
    if (partRefOutOfDate && !n.isTreeRoot) {
      return {
        ...(existingSubject || {}),
        hierarchy: n.hierarchy,
        message: <div>
          <Tooltip.Container className='relative'>
            <ExclamationTriangleIcon className='w-4 ml-1 text-yellow-500' />
            <Tooltip.Message className="bottom-10">
              Newer version v{n.part.proto.currentVersionString} available
            </Tooltip.Message>
          </Tooltip.Container>
        </div>
      }
    }

    const childrenOutOfDate = outOfDateParentsByLevel[n.hierarchy]
    if (childrenOutOfDate) {
      return {
        ...(existingSubject || {}),
        hierarchy: n.hierarchy,
        message: <div>
          <Tooltip.Container className='relative'>
            <ExclamationTriangleIcon className='w-4 ml-1 text-yellow-500' />
            <Tooltip.Message className="bottom-10">
              Uses out of date parts
            </Tooltip.Message>
          </Tooltip.Container>
        </div>
      }
    }

    const descendentsOutOfDate = outOfDateAncestorsByLevel[n.hierarchy]
    if (descendentsOutOfDate) {
      return {
        ...(existingSubject || {}),
        hierarchy: n.hierarchy,
        message: <div>
          <Tooltip.Container className='relative'>
            <ExclamationTriangleIcon className='w-4 ml-1 text-yellow-500' />
            <Tooltip.Message className="bottom-10">
              Parts in this tree are out of date
            </Tooltip.Message>
          </Tooltip.Container>
        </div>
      }
    }
    return existingSubject
  }).filter(Boolean) as SubjectPart[]
  return subjectParts
}

const CreateAggSpacer = ({ show }: { show?: boolean | 'full' }) =>
  show === 'full' ? <div className='inline-block w-14' />
    : show ? <div className='inline-block w-6' />
      : null

const alignToFlexAlign = (input?: 'center' | 'left' | 'right') => {
  if (input === 'center') return 'center'
  if (input === 'right') return 'end'

  // left is default
  return 'start'
}
