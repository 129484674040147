import React, { useEffect } from 'react'
import type { PropsWithChildren } from 'react'
import type {
  AppBodyQuery,
  AppBodyQueryVariables,
  UserViewMutation,
  UserViewMutationVariables,
  UserPageViewMutation,
  UserPageViewMutationVariables
} from 'types/graphql'

import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import AppContext, { useAppContextMaybe, useAppContextValue } from 'src/lib/appContext'
import { useMutation, type CellSuccessProps } from '@redwoodjs/web'

import { MetadataSchemaContext, MetadataSchema } from 'src/lib/metadata'
import NavBar from './NavBar'
import { useParams, useRouteName } from '@redwoodjs/router'
import Sentry from 'src/lib/sentry'
import { usePartProtosCache } from 'src/lib/usePartProtosCache'

export const QUERY = gql`
  query AppBodyQuery {
    currentOrg {
      id
      metadataSchema
      distributors {
        id
        name
      }
      lifeCycleStages {
        key
        name
        color
      }
      defaultPartVersion
      aggregationConfigs {
        name
        reducer
        targetType {
          type
          unit
        }
        metadata {
          key
          multiplyByQuantity
        }
        sources {
          key
          multiplyByQuantity
        }
      }
      manualExportMapper
      exchangeRates
    }
    partNumberSchemas {
      key
      templateConfig
      categories {
        id
        name
      }
    }
    partCategories {
      id
      name
      label
      useRange
      schema {
        key
        template
        templateConfig
      }
    }
    me {
      id
      name
      latestNotification
      email
      notificationsReadAt
      isSuperUser
      defaultOrgId
      memberOf {
        orgId
        role
      }
    }
  }
`

export const VIEW_MUTATION = gql`
  mutation UserViewMutation {
    updateUserLastLoggedInView
  }
`

export const PAGE_VIEW_MUTATION = gql`
  mutation UserPageViewMutation ($input: RegisterPageViewInput!) {
    registerPageView(input: $input)
  }
`

// TODO: Switch Navbar to a cell - it is not necessary for
// this to be a cell, it only serves to pass navbar its
// data right now

export const Loading = ({ children, width }: PropsWithChildren<{width?: 'full'}>) => {
  return <AppBody width={width}>{children}</AppBody>
}

export const Success = ({
  children,
  width,
  ...props
}: PropsWithChildren<CellSuccessProps<AppBodyQuery, AppBodyQueryVariables>> & { width?: 'full' }) => {
  return <AppBody width={width} data={props} metadataSchema={props.currentOrg.metadataSchema as MetadataSchema}>
      {children}
    </AppBody>
}

type AppBodyProps = PropsWithChildren<{
  data?: CellSuccessProps<AppBodyQuery, AppBodyQueryVariables> | undefined
  metadataSchema?: MetadataSchema,
  width?: 'full'
}>

const AppBody = ({children, data, metadataSchema, width}: AppBodyProps) => {
  const routeName = useRouteName()
  const { partNumber } = useParams()
  const [userView] = useMutation<
    UserViewMutation,
    UserViewMutationVariables
  >(VIEW_MUTATION)
  const [pageView] = useMutation<
    UserPageViewMutation,
    UserPageViewMutationVariables
  >(PAGE_VIEW_MUTATION)
  const { refresh: refreshProtos } = usePartProtosCache()
  useEffect(() => {
    (async () => {
      try {
        const d = await userView()
      } catch (e) {
        Sentry.captureException(e)
      }
    })()
  }, [])
  useEffect(() => {
    (async () => {
      if (routeName === 'part' && partNumber) {
        try {
          const d = await pageView({ variables: {
            input: {
              type: 'PartDetail',
              partNumber
            }
          } })
        } catch (e) {
          Sentry.captureException(e)
        }
      }
    })()
  }, [routeName, partNumber])
  useEffect(() => {
    // refresh protos on route change
    refreshProtos()
  }, [routeName])
  return <AppContext.Provider value={useAppContextValue(data)}>
    <MetadataSchemaContext.Provider value={metadataSchema as MetadataSchema}>
      <NavBar me={data?.me} key='stable' />
      <main className={width === 'full' ? 'pb-10' : 'px-16 max-w-screen-2xl mx-auto pb-10'}>
        <AppBodyGuarenteeContext>
          {children}
        </AppBodyGuarenteeContext>
      </main>
    </MetadataSchemaContext.Provider>
  </AppContext.Provider>
}

const AppBodyGuarenteeContext: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const appContext = useAppContextMaybe()
  if (!appContext) return <LoadingSpinner className='flex p-10 items-center justify-center'/>
  return children
}
