import type {
  AppBodyQuery,
  AppBodyQueryVariables,
} from 'types/graphql'
import type { CellSuccessProps } from '@redwoodjs/web'
import { createContext, useContext, Context } from "react";
import { useParams } from '@redwoodjs/router'

type AppResults = CellSuccessProps<AppBodyQuery, AppBodyQueryVariables>
export type AppContext = {
  me: AppResults['me'],
  membership?: AppResults['me']['memberOf'][number]
  role?: AppResults['me']['memberOf'][number]['role']
  isReadOnlyUser: boolean
  canEdit: boolean
  currentOrg: AppResults['currentOrg']
  partCategories: AppResults['partCategories']
  partNumberSchemas: AppResults['partNumberSchemas']
}
const appContext = createContext<AppContext | undefined>(undefined)
export default appContext

// all consumers must be in app body
// which ensures appContext
export const useAppContext = () => {
  const context = useContext(appContext)
  return context!
}

export const useAppContextMaybe = () => {
  return useContext(appContext)
}


export const useAppContextValue = (args: CellSuccessProps<AppBodyQuery> | undefined): AppContext | undefined => {
  if (!args) return;
  const { orgId } = useParams()
  const { me, currentOrg, partNumberSchemas, partCategories } = args;
  const membership = me.memberOf.find(m => m.orgId === orgId)
  const role = membership?.role;
  return {
    me,
    membership,
    partCategories,
    role,
    currentOrg,
    isReadOnlyUser: role === 'ReadOnly',
    canEdit: role !== 'ReadOnly',
    partNumberSchemas
  }
}

export const useRequiredContext = <ItemType>(context: Context<ItemType | null>, errorMsg?: string): ItemType => {
  const contextValue = useContext(context);
  if (contextValue === null) {
    throw Error(errorMsg ?? "Missing Required Context");
  }
  return contextValue;
}
