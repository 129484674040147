import type {
  PartProto,
  User,
  Project,
  UpdatePartSettingsMutation,
  UpdatePartSettingsMutationVariables,
  UpdatePartProjectMutation,
  UpdatePartProjectMutationVariables,
  CreatePartProjectMutation,
  CreatePartProjectMutationVariables,
  DeletePartProjectMutation,
  DeletePartProjectMutationVariables,
  RecursiveForkQuery,
  RecursiveForkQueryVariables,
  UpdateForkOfInput,
} from 'types/graphql'
import type { DocumentNode } from "graphql";
import { useState, useEffect, Fragment, useContext } from 'react';
import { useLazyQuery } from '@apollo/client'
import { Dialog, Transition } from '@headlessui/react'
import Button from 'src/components/Button'
import UserSelectCell from 'src/components/UserSelectCell';
import { useMutation } from '@redwoodjs/web'
import { reportMutationError } from 'src/lib/reportError';
import { Form, Submit, Label, TextField, FormError } from 'src/components/Form';
import Switch from 'src/components/Switch'
import { ControlledCategorySelect } from '../PartCategorySelect';
import { TemplateBlockConfigs } from 'shared/partNumbers'
import { useAppContext } from 'src/lib/appContext'
import PartSelectCell, { PartDetail } from '../PartSelectCell'
import * as ListBox from '../ListBox';
import { RECURSIVE_FORK_QUERY } from '../PartHistoryCell'
import { letF } from 'api/src/shared/functional'
import * as Tooltip from "src/components/ToolTip"

const MAIN_BRANCH = -1

type PartProtoInput = Pick<PartProto, 'partNumber' | 'categoryId'> & {
  owner: Pick<User, 'id' | 'name'>
  project?: Pick<Project, 'id' | 'name' | 'link'> | null
  forkFrom?: {
    partNumber: string
    version: string
  } | null
}
type PartSettingsDialogProps = {
  open: boolean
  partProto: PartProtoInput
  onClose: () => void
  refetchQueries: DocumentNode[]
}

export const UPDATE_PART_SETTINGS_MUTATION = gql`
  mutation UpdatePartSettingsMutation (
    $partNumber: String!
    $input: UpdatePartProtoInput!
  ) {
    updatePartProto(partNumber: $partNumber, input: $input) {
      partNumber
    }
  }
`

export const UPDATE_PART_PROJECT_MUTATION = gql`
  mutation UpdatePartProjectMutation (
    $projectId: ID!
    $rootPartNumber: String!
    $link: String
    $name: String!
    $defaultMapper: String!
  ) {
    updateProject(projectId: $projectId, input: {
      rootPartNumber: $rootPartNumber
      link: $link
      name: $name
      defaultMapper: $defaultMapper
    }) {
      id
    }
  }
`

export const CREATE_PART_PROJECT_MUTATION = gql`
  mutation CreatePartProjectMutation (
    $rootPartNumber: String!
    $link: String
    $name: String!
    $defaultMapper: String!
  ) {
    createProject(input: {
      rootPartNumber: $rootPartNumber
      link: $link
      name: $name
      defaultMapper: $defaultMapper
    }) {
      id
    }
  }
`

export const DELETE_PART_PROJECT_MUTATION = gql`
  mutation DeletePartProjectMutation (
    $projectId: ID!
  ) {
    deleteProject(projectId: $projectId)
  }
`

const PartSettingsDialog: React.FC<PartSettingsDialogProps> = ({ open, onClose, partProto, refetchQueries }) => {
  const [owner, setOwner] = useState(partProto.owner)
  const [isProject, setIsProject] = useState(Boolean(partProto.project))
  const [updatePartProto, { error: ue }] = useMutation<UpdatePartSettingsMutation, UpdatePartSettingsMutationVariables>(UPDATE_PART_SETTINGS_MUTATION)
  const [updatePartProject, { error: upe }] = useMutation<UpdatePartProjectMutation, UpdatePartProjectMutationVariables>(UPDATE_PART_PROJECT_MUTATION)
  const [createPartProject, { error: ce }] = useMutation<CreatePartProjectMutation, CreatePartProjectMutationVariables>(CREATE_PART_PROJECT_MUTATION)
  const [deletePartProject, { error: de }] = useMutation<DeletePartProjectMutation, DeletePartProjectMutationVariables>(DELETE_PART_PROJECT_MUTATION)

  const error = ue || upe || ce || de

  const [selectedCategory, setSelectedCategory] = useState<string>(partProto.categoryId)
  const appContext = useAppContext()

  const [currentRootPart, setCurrentRootPart] = useState<string | undefined>(partProto.forkFrom?.partNumber)
  const [versionsMap, setVersionsMap] = useState<{[partNumber: string]: string[]}>({})

  const [fetchVersions, fetchVersionsResults] = useLazyQuery<RecursiveForkQuery, RecursiveForkQueryVariables>(RECURSIVE_FORK_QUERY)

  useEffect(() => {
    if (currentRootPart && !versionsMap[currentRootPart]) {
      const runFetchVersions = async () => {
        const variables = { partNumber: currentRootPart, }
        const result = await fetchVersions({variables})

        if (result.data?.partProto) {
          const newMap = {
            ...versionsMap,
            [currentRootPart]: result.data.partProto.instances
              .filter(i => i.branch === MAIN_BRANCH)
              .map(i => i.version)
          }
          setVersionsMap(newMap)
        }
      }
      runFetchVersions()
    }
  }, [currentRootPart])

  const [selectedVersion, setSelectedVersion] = useState<string | undefined>(partProto.forkFrom?.version)

  const handleSetRootPart = (p: PartDetail) => {
    setCurrentRootPart(p.partNumber)
    setSelectedVersion(undefined)
  }

  const handleSetVersion = (v: string) => {
    setSelectedVersion(v)
  }

  const handleUnfork = () => {
    setCurrentRootPart(undefined)
    setSelectedVersion(undefined)
  }

  const editableCategories = appContext.partNumberSchemas.flatMap(schema => {
    const templateConfig = schema.templateConfig as TemplateBlockConfigs
    const isCategoryEditable = Object.values(templateConfig).every(block => {
      return !['categoryNumber', 'categoryId'].includes(block.type)
    })
    if (isCategoryEditable) return schema.categories
    return []
  })

  const category = () => {
    const editable = editableCategories.some(ec => ec.id === partProto.categoryId)
    return <div className='flex flex-col gap-3 text-sm'>
      <Label>Category</Label>
      <div className='text-sm text-gray-600'>Categories set via part number are not editable</div>
      <ControlledCategorySelect
        disabled={!editable}
        keepInternalState
        showOnly={editableCategories.map(c => c.id)}
        categories={appContext.partCategories}
        value={selectedCategory}
        onCategoryIdChange={c => setSelectedCategory(c)}
      />
    </div>
  }

  const handleClose = () => {
    onClose()
  }

  const resetState = () => {
    setOwner(partProto.owner)
    setIsProject(Boolean(partProto.project))
  }

  const forkChanged = ((currentRootPart !== partProto.forkFrom?.partNumber) ||
    (selectedVersion !== partProto.forkFrom?.version)) &&
    !(currentRootPart && !selectedVersion)
  const unlink = forkChanged && !currentRootPart

  type FormData = {
    projectName: string
    projectLink?: string
  }
  const updateSettings = async ({ projectName, projectLink }: FormData) => {
    const createNewProject = isProject && !partProto.project
    const updateProject = isProject && partProto.project
    const deleteProject = !isProject && partProto.project
    const noPojectActions =  !isProject && !partProto.project

    let forkOf: UpdateForkOfInput | undefined = undefined;
    if (forkChanged) {
      forkOf = {
        unlink,
        partNumber: currentRootPart,
        version: selectedVersion,
      }
    }

    // update part proto
    const variables: UpdatePartSettingsMutationVariables = {
      partNumber: partProto.partNumber,
      input: {
        categoryId: selectedCategory,
        ownerId: owner.id,
        forkOf
      }
    }
    const { errors } = await updatePartProto({
      variables,
      // if there are actions, don't refetch yet
      refetchQueries: noPojectActions ? refetchQueries : [],
      awaitRefetchQueries: noPojectActions
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Failed to update part settings`
      })
    }
    if (noPojectActions) {
      handleClose()
      return
    }

    if (createNewProject) {
      const createProjectVariables: CreatePartProjectMutationVariables = {
        rootPartNumber: partProto.partNumber,
        link: projectLink,
        name: projectName,
        defaultMapper: 'Solidworks'
      }
      const { errors } = await createPartProject({
        variables: createProjectVariables,
        refetchQueries,
        awaitRefetchQueries: true
      })
      if (errors) {
        reportMutationError({
          errors,
          variables,
          message: `Failed to create part project`
        })
      }
      handleClose()
      return
    }

    if (updateProject) {
      const updateProjectVariables: UpdatePartProjectMutationVariables = {
        projectId: partProto.project!.id,
        rootPartNumber: partProto.partNumber,
        link: projectLink,
        name: projectName,
        defaultMapper: 'Solidworks'
      }
      const { errors } = await updatePartProject({
        variables: updateProjectVariables,
        refetchQueries,
        awaitRefetchQueries: true
      })
      if (errors) {
        reportMutationError({
          errors,
          variables,
          message: `Failed to update part project`
        })
      }
      handleClose()
      return
    }

    if (deleteProject) {
      const deleteProjectVariables: DeletePartProjectMutationVariables = {
        projectId: partProto.project!.id,
      }
      const { errors } = await deletePartProject({
        variables: deleteProjectVariables,
        refetchQueries,
        awaitRefetchQueries: true
      })
      if (errors) {
        reportMutationError({
          errors,
          variables,
          message: `Failed to delete part project`
        })
      }
      handleClose()
      return
    }
  }

  const projectSettings = () => {
    if (!isProject) return null
    return <>
      <div className='flex flex-col gap-3'>
        <Label>Project Name</Label>
        <TextField defaultValue={partProto.project?.name || ''} name='projectName' placeholder='Your project name' />
      </div>
      <div className='flex flex-col gap-3'>
        <Label>Project Link</Label>
        <TextField defaultValue={partProto.project?.link || ''} name='projectLink' placeholder='http://pdm-link.com' />
      </div>
    </>
  }

  return <Transition.Root show={open} as={Fragment}>
  <Dialog as="div" className="relative z-10" onClose={handleClose}>
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      afterLeave={resetState}
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    </Transition.Child>

    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Dialog.Panel className="relative transform rounded-lg bg-white px-8 py-6 text-left shadow-xl transition-all min-w-[28rem]">
            <Form onSubmit={updateSettings}>
              <div className="">
                <Dialog.Title className="font-medium mb-5 text-lg text-gray-900">
                  Part Settings
                </Dialog.Title>
                <div className='flex flex-col gap-6 my-68'>
                  {category()}
                  <div className='flex flex-col gap-3'>
                    <Label>Owner</Label>
                    <UserSelectCell displayValue={owner.name} onSelect={setOwner}/>
                  </div>

                  <div className='flex flex-col gap-3'>
                    <div className='flex gap-2 items-center'>
                    <Label>Project</Label>
                    <Switch
                      accessibleDescription={'Turn on if this part is associated with a project'}
                      enabled={isProject}
                      onChange={enabled => setIsProject(enabled)} />
                    </div>
                    <div className='text-sm text-gray-600 max-w-xl'>Set the part as a project if it represents the root component of a project. Parts that are projects can be imported into change orders, and project information can be linked.</div>
                  </div>
                  {projectSettings()}
                  <div className='flex flex-col gap-3'>
                    <Label>Fork Of</Label>
                    <div className='flex gap-2'>
                      <PartSelectCell
                        className='text-xs'
                        value={currentRootPart}
                        placeholder={unlink ? 'Removing Fork' : undefined}
                        sort={(a, b) => {
                          if (a.partNumber === currentRootPart) return -1
                          if (b.partNumber === currentRootPart) return 1
                          return 0
                        }}
                        omit={[partProto.partNumber]}
                        onSelect={handleSetRootPart} />
                      <div className=''>
                        {letF(currentRootPart && versionsMap[currentRootPart], versions =>
                          (!fetchVersionsResults.loading && versions) &&
                            <ListBox.ListBox value='' disabled={versions.length === 0} onChange={handleSetVersion}>
                              {({ open, disabled }) => (<>
                                <div className="relative text-sm w-full">
                                  <ListBox.Button className='' displayValue={selectedVersion ? `v${selectedVersion}` : `Version`} disabled={disabled} />
                                  <ListBox.Options open={open}>
                                    {versions.map(v =>
                                      <ListBox.Option display={`v${v}`} value={v} key={v} />
                                    )}
                                  </ListBox.Options>
                                </div>
                              </>)}
                            </ListBox.ListBox>
                        )}
                      </div>
                      <Tooltip.Container>
                        <div className='group h-full' tabIndex={0}>
                          <button className='ml-auto h-full' onClick={handleUnfork} type='button' data-testid='unlink-form'>
                            <LinkSlash className='w-4'/>
                          </button>
                          <Tooltip.Message className="group-focus:visible group-focus:z-50 bottom-10">
                            Unlink fork
                          </Tooltip.Message>
                        </div>
                      </Tooltip.Container>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 flex gap-2 justify-end">
                <Button onClick={handleClose}>Cancel</Button>
                <Submit variant='primary' disabled={Boolean(currentRootPart && !selectedVersion)}>
                  Save
                </Submit>
              </div>
              <FormError error={error} wrapperClassName="text-white bg-red-500 w-full p-4 my-2 rounded" />
            </Form>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </div>
  </Dialog>
</Transition.Root>
}

export default PartSettingsDialog


const LinkSlash = ({className}: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className ?? `size-6`}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M13.181 8.68a4.503 4.503 0 0 1 1.903 6.405m-9.768-2.782L3.56 14.06a4.5 4.5 0 0 0 6.364 6.365l3.129-3.129m5.614-5.615 1.757-1.757a4.5 4.5 0 0 0-6.364-6.365l-4.5 4.5c-.258.26-.479.541-.661.84m1.903 6.405a4.495 4.495 0 0 1-1.242-.88 4.483 4.483 0 0 1-1.062-1.683m6.587 2.345 5.907 5.907m-5.907-5.907L8.898 8.898M2.991 2.99 8.898 8.9" />
    </svg>
  )
}
