import {
  ChangeOrderChangesQuery,
  ChangeOrderChangesQueryVariables,
} from 'types/graphql'
import type { CellSuccessProps } from '@redwoodjs/web'

import { CHANGE_ORDER_CHANGES_QUERY } from 'src/lib/queries'

import calculateAllChanges from 'src/components/ChangeOrderChangesCell/calculateAllChanges'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import Button from 'src/components/Button'
import LoadingSpinner from 'src/components/Loading'
import { useLifeCycleStages } from 'src/lib/lifecycle'

import { ChangeExportButtons, tabularizeChanges, useChangeExport  } from 'src/components/ExportChanges'

export const QUERY = CHANGE_ORDER_CHANGES_QUERY

type ChangeOrderChanges = CellSuccessProps<ChangeOrderChangesQuery, ChangeOrderChangesQueryVariables>;
export const Loading = () => <div className='flex justify-center'> <LoadingSpinner/> </div>
import GenericFailure from '../Failure/Failure'
export const Failure = GenericFailure

//not sure why cell is getting exported wrong, requiring these params
export const Success = ({ onComplete, ...props }: Partial<ChangeOrderChanges> & { onComplete: () => void }) => {
  const lifeCycleStages = useLifeCycleStages()
  const changeOrder = props.changeOrder!
  const currentOrg = props.currentOrg!
  const sortedParts = ([...changeOrder.includedParts]).sort((a, b) => {
    if (a.isRoot && !b.isRoot) return -1
    if (!a.isRoot && b.isRoot) return 1
    if (a.dependencies.length !== b.dependencies.length) {
      return a.dependencies.length > b.dependencies.length ? -1 : 1
    }
    const aPartNumber = a.proto.partNumber
    const bPartNumber = b.proto.partNumber
    return aPartNumber > bPartNumber ? 1 : -1
  })
  const parts = calculateAllChanges(currentOrg, sortedParts)
  const changedParts = parts.filter(change => change.type !== 'none')
  if (!changedParts.length) {
    return <div>
      <div className='flex'>
      <ExclamationCircleIcon className='w-6 mr-3 text-yellow-400'/>
      <div>There are no changes in the ECO</div>
      </div>
      <div className='flex gap-2 mt-4'>
        <Button type='button' onClick={() => onComplete()} >Back</Button>
      </div>
    </div>
  }

  const table = tabularizeChanges({lifeCycleStages, parts, currentOrg})

  const fileName = currentOrg.name + '_ChangeOrder_#' + changeOrder.number

  const exportParams = useChangeExport({table, parts, fileName})
  return <ChangeExportButtons title={'Export Change Order'}  exportParams={exportParams} onComplete={onComplete} />
}
