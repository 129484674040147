import { PartChangePart, ArtifactChangeType, PartDiffSummary } from 'src/components/ChangeOrderChangesCell/calculateAllChanges'
import { EDIT_PART_MUTATION } from 'src/components/ChangeOrderChangesCell'
import { CHANGE_ORDER_CHANGES_QUERY, CHANGE_ORDER_QUERY } from 'src/lib/queries'

import { useRef, useState, useContext } from 'react'

import AppContext from 'src/lib/appContext';
import { reportMutationError } from 'src/lib/reportError'
import {
  EditPartMutation,
  EditPartMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { XMarkIcon } from '@heroicons/react/16/solid'
import { TrashIcon, ExclamationTriangleIcon, EyeIcon } from '@heroicons/react/24/outline'
import Button from '../Button'
import { ArrowUpTrayIcon, ArrowDownTrayIcon, ArrowUturnLeftIcon } from '@heroicons/react/20/solid'

import useFileUploader, { FileRef } from 'src/lib/hooks/useFileUploader'
import { ProgressBar } from "@uppy/react"

type ArtifactChangesProps = {
  changeOrderNumber?: number
  changeOrderComplete: boolean
  partDiff: PartDiffSummary
  isExpanded?: boolean
}

export const ArtifactChanges: React.FC<ArtifactChangesProps> = ({
  partDiff,
  changeOrderNumber,
  changeOrderComplete,
  isExpanded
}) => {
  const { headPart, incomingPart } = partDiff
  if (!headPart) {
    return <div>
      <div className='text-sm font-semibold text-gray-900 h-8 mb-2'>Artifacts</div>
      <Artifacts changeOrderNumber={changeOrderNumber} editable={!changeOrderComplete} partNumber={incomingPart.partNumber} part={incomingPart} />
    </div>
  }

  const artifactChanges = partDiff.fields.artifacts.change
  const changedIds = (artifactChanges || []).filter(a => {
    return a.changeType !== 'nothing'
  }).map(a => a.artifact.id)
  const onlyShow = isExpanded ? undefined : changedIds

  if (changedIds.length === 0 && !isExpanded) {
    return null
  }

  return <div className=''>
    <div className='flex'>
      <div className='flex-1'>
        <div className='text-sm font-semibold text-gray-900 h-8 mb-2'>Artifacts</div>
        <Artifacts onlyShow={onlyShow} changeOrderNumber={changeOrderNumber} compact partNumber={headPart.partNumber} part={headPart} />
      </div>
      <div className='border-l border-gray-200 mx-10'></div>
      <div className='flex-1'>
        <div className='text-sm font-semibold text-gray-900 h-8 mb-2'>Artifacts</div>
        <Artifacts onlyShow={onlyShow} changeOrderNumber={changeOrderNumber} compact editable={!changeOrderComplete} partNumber={incomingPart.partNumber} part={incomingPart} changes={artifactChanges} />
      </div>
    </div>
  </div>
}

type ArtifactsProps = {
  editable?: boolean
  compact?: boolean
  part: Pick<PartChangePart, 'artifacts' | 'version'>,
  partNumber: string
  changeOrderNumber?: number
  changes: undefined
  onlyShow?: number[]
} | {
  editable?: boolean
  compact?: boolean
  part: PartChangePart,
  partNumber: string
  changeOrderNumber?: number
  changes?: PartDiffSummary['fields']['artifacts']['change']
  onlyShow?: number[]
}

const NewArtifactZone = ({ compact, part, partNumber, changeOrderNumber }: Omit<ArtifactsProps, 'editable'>) => {
  const dialogRef = useRef<HTMLDialogElement>(null)
  const [fileCollision, setFileCollision] = useState<string[] | undefined>(undefined)

  const [editPartMutation, { loading, error }] = useMutation<EditPartMutation, EditPartMutationVariables>(EDIT_PART_MUTATION)
  const updateArtifacts = async (artifacts: EditPartMutationVariables['artifacts']) => {
    const variables = {
      partNumber,
      changeOrderNumber: changeOrderNumber!,
      version: part.version,
      artifacts
    }
    const { errors } = await editPartMutation({
      variables: variables,
      refetchQueries: [
        { query: CHANGE_ORDER_QUERY, variables: { orderNumber: changeOrderNumber } },
        { query: CHANGE_ORDER_CHANGES_QUERY, variables: { orderNumber: changeOrderNumber } }
      ],
      awaitRefetchQueries: true
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error mutating part artifacts`
      })
    }
  }

  const saveEdit = async (newFiles: FileRef[]) => {
    const { collisions, additions } = newFiles.reduce(
      (acc, f) => {
        const collision = part.artifacts.find(a => a.filename === f.path)
        if (collision) acc.collisions[collision.id] = f
        else acc.additions.push(f)
        return acc
      },
      { collisions: {}, additions: [] } as { collisions: { [id: string]: FileRef }, additions: FileRef[] }
    )

    const artifactsDelta = part
      .artifacts.map(a => collisions[a.id] ?
        { fileId: collisions[a.id]!.id, filename: collisions[a.id]!.path }
        : { fileId: a.fileId, filename: a.filename })
      .concat(additions.map(f => ({ fileId: f.id, filename: f.path })))

    updateArtifacts(artifactsDelta);
  }

  const onFilenameCollision = async (collisions: { filename: string }[]) => {
    if (!dialogRef.current) {
      return true;
    }
    else {
      setFileCollision(collisions.map(c => c.filename));
      let resolve: (value: boolean) => void | undefined;
      const r = new Promise<boolean>(res => {
        resolve = res;
      })
      const listener = (e: Event) => {
        dialogRef.current?.removeEventListener('close', listener);

        const target = e.target as EventTarget & {
          returnValue: string
        }
        if (target.returnValue !== "ok") {
          resolve?.(false);
        }
        else {
          resolve?.(true);
        }
      }
      dialogRef.current.addEventListener('close', listener);
      dialogRef.current.showModal()
      return await r;
    }
  }

  const checkFileCollision = async (files: File[]) => {
    const collisions = part.artifacts.filter(a =>
      files.find(f => f.name === a.filename))

    if (collisions.length) {
      return await onFilenameCollision(collisions);
    }
    else {
      return true;
    }
  }

  const fileUploader = useFileUploader(checkFileCollision, fileRefs => fileRefs && saveEdit(fileRefs))

  const dragClass = fileUploader.dragState.inDropZone ? '!border-blue-600' : ''

  const stripes = fileUploader.uploading ? {
    background: `repeating-linear-gradient( 45deg, #fff, #fff, 10px, #eee 10px, #eee 20px)`
  } : undefined
  return <div>
    <dialog ref={dialogRef} className='rounded-lg bg-white px-6 pb-4 pt-5 text-left shadow-xl fixed top-20'>
      <div className='items-center gap-6 flex flex-col'>
        <ExclamationTriangleIcon className='w-16 h-16 text-yellow-500' />
        <div className='flex flex-col gap-4'>
          {fileCollision && (
            fileCollision.length === 1 ?
              <p className='text-sm text-gray-700'>Replace “{fileCollision[0]}”?</p> :
              <p className='text-sm text-gray-700'>Replace the files {fileCollision.map(c => `"${c}"`).join(", ")}?</p>
          )}
          <form method="dialog" className='mt-3 flex gap-4'>
            <Button className='w-full' value="cancel" type="submit">Cancel</Button>
            <Button className='w-full' variant='primary' value="ok" type="submit">Replace</Button>
          </form>
        </div>
      </div>
    </dialog>
    <label>
      <div style={stripes} className={`flex flex-col text-sm items-center h-10 px-4 justify-center border border-gray-300 border-dashed rounded-lg ${dragClass} has-[:focus]:border-blue-600 hover:cursor-pointer has-[input:disabled]:cursor-not-allowed`}
        {...fileUploader.dragBindings} data-testid={`${partNumber}-artifact-drop`}>
        <div className='flex gap-2 items-center text-gray-600'>
          <ArrowUpTrayIcon className='h-4 w-4' />
          <div>
            <span className='font-semibold'>Click to import</span>
            <span>&nbsp;or drag and drop a file (max 500Mb)</span>
          </div>
        </div>
        <input {...fileUploader.inputBindings} disabled={fileUploader.uploading} className="opacity-0 h-0" />
      </div>
    </label>
    {fileUploader.error &&
      <div className='p-2 flex items-center gap-2 bg-red-200 hover:cursor-pointer' onClick={fileUploader.clearError}>
        <XMarkIcon className='h-5 w-5' />
        <small>
          There was an issue uploading the file. Message: <code> {fileUploader.error?.message ?? "Unknown"} </code>
        </small>
      </div>
    }
    <ProgressBar uppy={fileUploader.uppy} />
  </div>
}

const viewableBroadTypes = new Set(['text', 'image'])
const viewableSubTypes = new Set(['pdf'])
const ViewFileInline = ({file}: Pick<ArtifactsProps['part']['artifacts'][number], 'file'>) => {
  const [broadType, subType] = file.contentType?.split('/') ?? []
  const viewable = broadType && viewableBroadTypes.has(broadType) || subType && viewableSubTypes.has(subType)
  return (
    viewable &&
      <a target='blank' href={file.inlineUrl}>
        <EyeIcon className='h-4 w-4' />
      </a>
  )
}

const EditArtifactZone = ({ artifact, changeType, concreteIndex, ...props }: { changeType?: ArtifactChangeType, concreteIndex?: number, artifact: ArtifactsProps['part']['artifacts'][number] } & Omit<ArtifactsProps, 'editable'>) => {
  const { part, partNumber, changeOrderNumber, compact } = props;
  const [editPartMutation, { loading, error }] = useMutation<EditPartMutation, EditPartMutationVariables>(EDIT_PART_MUTATION)
  const applyDelta = async (artifactsDelta: EditPartMutationVariables['artifacts']) => {
    const variables = {
      partNumber,
      changeOrderNumber: changeOrderNumber!,
      version: part.version,
      artifacts: artifactsDelta
    }
    const { errors } = await editPartMutation({
      variables: variables,
      refetchQueries: [
        { query: CHANGE_ORDER_QUERY, variables: { orderNumber: changeOrderNumber } },
        { query: CHANGE_ORDER_CHANGES_QUERY, variables: { orderNumber: changeOrderNumber } }
      ],
      awaitRefetchQueries: true
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error mutating part artifacts`
      })
    }
  }

  const deleteArtifact = async () => {
    const artifactsDelta = part.artifacts
      .filter(a => a.id !== artifact.id)
      .map(a => ({ fileId: a.fileId, filename: a.filename }))

    await applyDelta(artifactsDelta);
  }

  const restoreArtifact = async () => {

    const artifactsDelta = part
      .artifacts.map(a =>
        ({ fileId: a.fileId, filename: a.filename }))

    artifactsDelta.splice(concreteIndex!, 0, { fileId: artifact.fileId, filename: artifact.filename })

    await applyDelta(artifactsDelta);
  }

  const background = (!changeType || changeType === 'nothing') ? 'bg-white' : changeType === 'edit' ? 'bg-yellow-100' : changeType === 'deletion' ? 'bg-red-200' : 'bg-green-200'

  const linethrough = changeType === 'deletion' ? 'line-through' : ''
  return <>
    <div className={`flex flex-col items-center h-10 px-3 justify-center ${background} border border-gray-300 rounded-lg has-[input:focus]:border-blue-600`} >
      <div className='flex gap-2 justify-items-start w-full items-center text-gray-600 text-sm'>
        <div className={linethrough + ' flex-1 relative h-5'}>
          <div className={`absolute left-0 top-0 right-0 truncate hover:overflow-y-visible hover:text-clip hover:whitespace-normal ${background}`} data-testid={`${partNumber}-artifact-${artifact.filename}-${changeType ?? 'unchanged'}`}>
            {artifact.filename}
          </div>
        </div>
        {changeType === 'deletion' ?
          <>
            <button onClick={restoreArtifact} className='ml-auto'>
              <ArrowUturnLeftIcon className='h-4 w-4' />
            </button>
          </>
          :
          <>
            <button onClick={deleteArtifact} className='ml-auto' data-testid={`${partNumber}-delete-artifact-${artifact.filename}`}>
              <TrashIcon className='h-4 w-4' />
            </button>
            <a download href={artifact.file.url}>
              <ArrowDownTrayIcon className='h-4 w-4' />
            </a>
            <ViewFileInline file={artifact.file}/>
          </>
        }
      </div>
    </div>
  </>
}
const ArtifactItem = ({ artifact, compact, changeType }: { compact?: boolean, artifact: ArtifactsProps['part']['artifacts'][number], changeType?: ArtifactChangeType }) => {
  const backgrounds = {
    nothing: '',
    edit: 'bg-yellow-100',
    deletion: 'bg-red-200',
    addition: 'bg-green-200'
  }
  const background = changeType ? backgrounds[changeType as ArtifactChangeType] : ''
  const linethrough = changeType === 'deletion' ? 'line-through' : ''
  return (
    <div className={`flex flex-col items-center h-10 px-3 justify-center border border-gray-200 rounded-lg ${background}`}>
      <div className='flex gap-2 justify-items-start w-full items-center text-gray-600 text-sm'>
        <div className={linethrough} data-testid={`basic-artifact-${artifact.filename}-${changeType ?? 'unchanged'}`}>
          {artifact.filename}
        </div>
        <a download href={artifact.file.url} className='ml-auto'>
          <ArrowDownTrayIcon className='h-4 w-4' />
        </a>
        <ViewFileInline file={artifact.file}/>
      </div>
    </div>
  )
}



export const Artifacts: React.FC<ArtifactsProps> = ({ editable, ...rest }) => {
  const appContext = useContext(AppContext)

  const { compact, part, changes, onlyShow } = rest;
  const margin = compact ? '-mx-1' : '-mx-1'

  const renderDropzones = () => {
    if (editable && appContext?.canEdit) {
      return <>
        <div className={`flex flex-col gap-3 ${margin}`}>
          {changes ?
            changes.map(c => {
              return <EditArtifactZone key={c.artifact.id} artifact={c.artifact} changeType={c.changeType} concreteIndex={c.concreteIndex} {...rest} />
            }
            )
            : part.artifacts.map(a => <EditArtifactZone key={a.id} artifact={a} {...rest} />)}
          <NewArtifactZone {...rest} />
        </div>
      </>
    }
    const artifacts = () => {
      const empty = <div className='text-gray-600 rounded-lg bg-gray-100 italic text-sm justify-center flex items-center h-10 px-4'>
        This part doesn't contain any artifacts
      </div>
      if (!changes?.length && !part.artifacts.length) {
        return empty
      }
      if (changes) {
        const renderedItems = onlyShow ?
          changes.filter(change => onlyShow.includes(change.artifact.id)) : changes
        return renderedItems.map(c => {
          return <ArtifactItem key={c.artifact.id} artifact={c.artifact} changeType={c.changeType} {...rest} />
        })
      }

      const renderedItems = onlyShow ?
        part.artifacts.filter(a => onlyShow.includes(a.id)) :  part.artifacts
      return renderedItems.map(a => <ArtifactItem key={a.id} artifact={a} compact={rest.compact} />)
    }
    return <div className={`flex flex-col gap-3 text-gray-600 ${margin}`}>
      {artifacts()}
    </div>
  }

  return <div className='flex-1'>
    {renderDropzones()}
  </div>
}
