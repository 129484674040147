import {
  ChangeOrder,
  Part,
  PartHierarchy as PartHierarchyType,
  Dependency,
  Maybe,
  PartCategory,
  PartProto,
  PartTreeNodeFragment,
  PartTreeNodePartFragment
} from 'types/graphql'

import { SubjectPart, HierarchyContainer, RootInput, useHierarchyControllerFactory, PartHierarchyHeaderColumn } from '../PartHierarchy/PartHierarchy'

type OrphanPartHierarchyDependency = Pick<Dependency, 'quantity' | 'section' | 'units' | 'referenceDesignator' | 'groupId'> & {
  to: Pick<Part, 'partNumber' | 'version'>
}


type IncludedPartDependency = Pick<Dependency, 'section' | 'quantity' | 'units' | 'referenceDesignator' | 'groupId' | 'toVersionRange'> & {
  to: Pick<Part, 'partNumber' | 'version'> & {
    proto: Pick<PartProto, 'currentVersionString'> & {
      category: Pick<PartCategory, 'name'>
      currentVersion?: {
        dependencies: Pick<Dependency, 'toPartNumber'>[]
      }
    }
    dependencies: OrphanPartHierarchyDependency[]
  }
}

type IncludedPartHead = Pick<Part, 'partNumber' | 'version'> & {
  dependencies: IncludedPartDependency[]
}
export type IncludedPart = IncludedPartHead & {
  updateTo?: Maybe<IncludedPartHead>
}
type VersionChangeSummaryProps = {
  changeOrder: Pick<ChangeOrder, 'number'> & {
    baseOrphans: {
      tree: PartTreeNodeFragment[]
    }[]
    includedParts: IncludedPart[]
  }
}

const VersionChangeSummary: React.FC<VersionChangeSummaryProps> = React.memo(({ changeOrder }) => {
  const { includedParts } = changeOrder

  const includedPartsByNumber = includedParts.reduce((memo, p) => {
    return {
      ...memo,
      [p.partNumber]: p
    }
  }, {} as Record<string, typeof includedParts[number]>)
  const columns: PartHierarchyHeaderColumn[] = [{
    name: 'Version',
    key: 'version',
    type: 'field',
    width: 150,
    align: 'left'
  }]

  const hierarchies = changeOrder.baseOrphans.map((orphan): RootInput => {
    // const rootNode: OrphanPartHierarchy = {
    //   part: { ...orphan },
    //   hierarchy: '1',
    // }
    const rootNode = orphan.tree.find(n => n.isTreeRoot)!
    const rootInCo = includedPartsByNumber[rootNode.part.partNumber]!

    type NodeInfo = {
      dereferenced: boolean,
      updated: boolean,
      isNew: boolean,
      isNewReference: boolean,
      previousVersion?: string
      previousVersionRange?: string
      node: PartTreeNodeFragment,
      parent?: PartTreeNodeFragment
    }

    const rootInfo: NodeInfo = {
      node: rootNode,
      dereferenced: false,
      updated: Boolean(rootInCo?.updateTo),
      isNew: !rootInCo?.updateTo,
      isNewReference: false,
      previousVersion: rootInCo?.updateTo?.version
    }


    // from the root, check each child for changes and add to diffTree
    // - if a version update in the change order, then add and add children recursively
    // - if a version update outside of the change order, or a newly pinned version then
    //   show the update but don't show it's children
    // - for removed references, show in red but no children
    // - for added references, show in green and add children
    let diffTree: NodeInfo[] = [rootInfo]

    const addUpdatedChildren = (parent: PartTreeNodeFragment) => {
      const parentInChangeOrder = includedPartsByNumber[parent.part.partNumber]!
      const previousParent = parentInChangeOrder.updateTo

      const previousDependenciesByPartNumber: Record<string, Pick<Dependency, 'toVersionRange'> & {
        to: Pick<Part, "partNumber" | "version">
      }> = {}
      for (const child of (previousParent?.dependencies || [])) {
        previousDependenciesByPartNumber[child.to.partNumber] = child
      }

      const children = findChildren(orphan.tree, parent.hierarchy)

      // add updated and new parts
      for (const child of children) {
        const childInCo = includedPartsByNumber[child.part.partNumber]

        const previousDep = previousDependenciesByPartNumber[child.part.partNumber]

        const info = {
          node: child,
          dereferenced: false,
          updated: Boolean(previousDep && (previousDep.to.version !== child.part.version || previousDep.toVersionRange !== child.parentToNodeDependency?.toVersionRange)),
          isNew: Boolean(childInCo && !childInCo.updateTo),
          isNewReference: !previousDep,
          previousVersion: previousDep?.to.version,
          previousVersionRange: previousDep?.toVersionRange
        }

        if (info.isNew || info.isNewReference || info.updated) {
          diffTree = [...diffTree, info]
        }

        // only parts in change order have their children rendered
        if (childInCo && childInCo.version === child.part.version) {
          addUpdatedChildren(child)
        }
      }

      // add dereferenced parts
      const childrenByPartNumber: Record<string, true> = {}
      for (const child of children) {
        childrenByPartNumber[child.part.partNumber] = true as const
      }

      // children are indexed starting at 1
      let deletedChildIndex = children.length + 2

      for (const dep of previousParent?.dependencies || []) {
        if (!childrenByPartNumber[dep.to.partNumber]) {
          diffTree = [...diffTree, {
            node: {
              parentToNodeDependency: {
                ...dep
              },
              isTreeRoot: false,
              // using the full fragment for a dereferenced
              // part would make the change order query massive
              part: {
                ...dep.to,
              } as unknown as PartTreeNodePartFragment,
              hierarchy: `${parent.hierarchy}.${deletedChildIndex}`
            },
            dereferenced: true,
            updated: false,
            isNew: false,
            isNewReference: false,
            previousVersion: dep.to.version
          }]
          deletedChildIndex++
        }
      }
    }

    addUpdatedChildren(rootNode)

    const subjectParts = diffTree.map(nodeInfo => {
      const color = () => {
        if (nodeInfo.dereferenced) return 'bg-red-100'
        if (nodeInfo.isNew) return 'bg-green-100'
        if (nodeInfo.isNewReference) return 'bg-green-100'
        if (nodeInfo.updated) return 'bg-yellow-100'
        return ''
      }

      return {
        hierarchy: nodeInfo.node.hierarchy,
        className: color(),
      } as SubjectPart
    })

    // expand everything
    const expandedNodes = diffTree.map(info => info.node.hierarchy)


    const tree = diffTree.map(info => {
      const { node } = info
      const withVersion = {
        ...node,
        part: {
          ...node.part,
          version: info.node.part.version
        },
        previousVersion: info.previousVersion,
        previousVersionRange: info.previousVersionRange
      }

      // do not render groups in the change hierarchy
      if (node.parentToNodeDependency) {
        withVersion.parentToNodeDependency = {
          ...node.parentToNodeDependency,
          groupId: undefined
        }
      }

      return withVersion
    })


    const expandedWithoutRoot = expandedNodes.filter(h => h !== '1')

    return {
      tree,
      subjectParts,
      initiallyExpandedNodes: expandedWithoutRoot,
    }
  })

  console.log({hierarchies})

  const controller = useHierarchyControllerFactory({
    roots: hierarchies,
    usageContext: {
      type: 'ChangeOrder',
      changeOrder: changeOrder
    },
    columns
  })
  return hierarchies.length > 0 ?
    <HierarchyContainer controller={controller} /> :
    <div className='text-center italic text-gray-600 bg-gray-100 p-5 rounded-md'>
      No parts in the change order are referenced by parent assemblies
    </div>
})

export default VersionChangeSummary

type FindAncestorHierarchy = {
  hierarchy: string
}

const findChildren = <T extends FindAncestorHierarchy[]>(fullHierarchy: T, parentLevel: string) => {
  const hierarchy = parentLevel.split('.');
  const childFinder = new RegExp('^' + hierarchy.join('[.]') + '[.]\\d+$')

  const children = fullHierarchy
    .filter(p => childFinder.test(p.hierarchy))
  return children as T
}
