import classNames from 'classnames'
import * as HeadlessForm from '@redwoodjs/forms'
import { twMerge } from 'tailwind-merge'

const outerInputClasses = 'relative rounded-md shadow-sm'

const baseInputClasses = `
block w-full rounded-md border-0 py-2 h-8 px-3 text-gray-900
ring-1 ring-inset ring-gray-300 placeholder-gray-400
focus:ring-2 focus:ring-inset focus:ring-brand-500 text-sm
`
const compactInputClasses = `
${baseInputClasses} py-1 px-1
`
export const inputClasses = `
${baseInputClasses} px-3
`

type InputProps = {
  className?: string
  icon?: React.ReactNode
  placeholder?: string
  defaultValue?: string
  value?: string
  onChange?: (value: string) => void
  inputClassName?: string
}
const Input: React.FC<InputProps> = ({ className, icon, placeholder, onChange, value, defaultValue, inputClassName }) => {
  const handleChange = onChange ?? (() => { });
  const outerClasses = classNames(outerInputClasses, className);
  const iconBefore = icon ? <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">{icon}</div> : null;
  const inputClassNameResolved = twMerge(classNames(inputClasses,
    {
      'pl-10': icon
    }
  ), inputClassName)
  return (
    <div className={outerClasses}>
      {iconBefore}
      <input
        defaultValue={defaultValue}
        value={value}
        onChange={e => handleChange(e.target.value)}
        type="text"
        className={inputClassNameResolved}
        placeholder={placeholder}
      />
    </div>
  )
}
export default Input;

type FormTextField = {
  compact?: boolean
  className?: string
  icon?: React.ReactNode,
  leadingAddOn?: React.ReactNode,
  trailingAddOn?: React.ReactNode,
  inputClassName?: string
} & Omit<React.ComponentProps<typeof HeadlessForm.TextField>, 'name'> & ({
  controlled: true
  name?: string
} | {
  controlled?: false
  name: string
});

// If needed, import this from ./Form.tsx. The source code is here
// because it shares classes with Input.
export const FormTextField: React.FC<FormTextField> = ({controlled, ...props}) => {
  const outerClasses = twMerge(outerInputClasses, props.className);
  const beforeNode = props.icon || props.leadingAddOn
  const before = beforeNode ? <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">{beforeNode}</div> :
    null;
  const after = props.trailingAddOn ? <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">{props.trailingAddOn}</div> :
    null;
  const inputClassName = classNames(props.compact ? compactInputClasses : inputClasses,
    {
      'pl-10': props.icon,
      'text-gray-400 bg-gray-50': props.disabled
    },
  )
  const className = twMerge(inputClassName, props.inputClassName)
  return (
    <div className={outerClasses}>
      {before}
      {controlled ?
        <input
          {...props}
          className={className}
        />
        :
        <HeadlessForm.TextField
          {...props}
          className={className}
        />
      }
      {after}
    </div>
  )
}

type FormNumberFieldProps = {
  compact?: boolean
  className?: string
  icon?: React.ReactNode,
  leadingAddOn?: React.ReactNode,
  trailingAddOn?: React.ReactNode,
  inputClassName?: string
} & Omit<React.ComponentProps<typeof HeadlessForm.NumberField>, 'name'> & ({
  controlled: true
  name?: string
} | {
  controlled?: false
  name: string
});

// If needed, import this from ./Form.tsx. The source code is here
// because it shares classes with Input.
export const FormNumberField: React.FC<FormNumberFieldProps> = (allProps) => {
  const {
    controlled,
    inputClassName,
    className: outerClass,
    leadingAddOn,
    trailingAddOn,
    icon,
    compact,
    ...props } = allProps
  const outerClasses = twMerge(outerInputClasses, outerClass);
  const beforeNode = icon || leadingAddOn
  const before = beforeNode ? <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">{beforeNode}</div> :
    null;
  const after = trailingAddOn ? <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">{trailingAddOn}</div> :
    null;
  const iClassName = classNames(compact ? compactInputClasses : inputClasses,
    {
      'pl-10': icon
    },
  )
  const className = twMerge(inputClassName, iClassName, props.disabled ? 'text-gray-400' : '')
  return (
    <div className={outerClasses}>
      {before}
      {controlled ?
        <input type='number' {...props} className={className}/> :
        <HeadlessForm.NumberField
          {...props}
          className={className}
        />
      }
      {after}
    </div>
  )
}

type FormCheckboxFieldProps = {
  className?: string
  icon?: React.ReactNode
} & React.ComponentProps<typeof HeadlessForm.NumberField>;

// If needed, import this from ./Form.tsx. The source code is here
// because it shares classes with Input.
export const FormCheckboxField: React.FC<FormCheckboxFieldProps> = (props) => {
  const outerClasses = classNames(outerInputClasses, props.className);
  const iconBefore = props.icon ? <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">{props.icon}</div> : null;
  const inputClassName = classNames(inputClasses,
    {
      'pl-10': props.icon
    }
  )
  return (
    <div className={outerClasses}>
      {iconBefore}
      <HeadlessForm.CheckboxField
        {...props}
        className={inputClassName}
      />
    </div>
  )
}

type FormDatetimeFieldProps = {
  className?: string
  icon?: React.ReactNode
} & React.ComponentProps<typeof HeadlessForm.NumberField>;

// If needed, import this from ./Form.tsx. The source code is here
// because it shares classes with Input.
export const FormDatetimeField: React.FC<FormDatetimeFieldProps> = (props) => {
  const outerClasses = classNames(outerInputClasses, props.className);
  const iconBefore = props.icon ? <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">{props.icon}</div> : null;
  const inputClassName = classNames(inputClasses,
    {
      'pl-10': props.icon
    }
  )
  return (
    <div className={outerClasses}>
      {iconBefore}
      <HeadlessForm.DatetimeLocalField
        {...props}
        className={inputClassName}
      />
    </div>
  )
}

type FormUrlFieldProps = {
  className?: string
  icon?: React.ReactNode
} & React.ComponentProps<typeof HeadlessForm.UrlField>;

// If needed, import this from ./Form.tsx. The source code is here
// because it shares classes with Input.
export const FormUrlField: React.FC<FormUrlFieldProps> = (props) => {
  const outerClasses = classNames(outerInputClasses, props.className);
  const iconBefore = props.icon ? <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">{props.icon}</div> : null;
  const inputClassName = classNames(inputClasses,
    {
      'pl-10': props.icon
    }
  )
  return (
    <div className={outerClasses}>
      {iconBefore}
      <HeadlessForm.UrlField
        {...props}
        className={inputClassName}
      />
    </div>
  )
}
