import React, { useRef, useState, CSSProperties } from 'react'
import { Controller } from '@redwoodjs/forms'
import type { PartCategory } from 'types/graphql'
import * as ListBox from '../ListBox'
import { useAppContext } from 'src/lib/appContext'
import { useBoundingRect } from '../hooks'

export type Category = Pick<PartCategory, 'id' | 'name' | 'label'>

type UncontrolledProps = {
  categories: Category[]
  showOnly: string[]
  value: string
  disabled?: boolean
  className?: string
  autoFocus?: boolean
  size?: 'sm' | 'md'
  keepInternalState?: boolean
  onCategoryIdChange: (c: string) => void
}

export const ControlledCategorySelect: React.FC<UncontrolledProps> = ({ size, disabled, value, categories, className, showOnly, onCategoryIdChange, keepInternalState }) => {
  const [lastValue, setLastValue] = useState(value)
  const val = keepInternalState ? lastValue : value
  const selectedCategory = categories.find(c => c.id === val)
  const sortedCategories = [...categories].sort((a, b) => {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })

  return <ListBox.ListBox onChange={(c) => {onCategoryIdChange(c); setLastValue(c) }} as='div'>
  <ListBox.Button
    size={size}
    disabled={disabled}
    displayValue={selectedCategory!.name}
    className={className} />
  <ListBox.Options align='left'>
    {sortedCategories.filter(c => !showOnly || showOnly.includes(c.id)).map((category) => (
      <ListBox.Option key={category.id} value={category.id} display={category.name} />
    ))}
  </ListBox.Options>
</ListBox.ListBox>
}

type Props = {
  name: string
  displayValue: string
  defaultValue?: string
  required: boolean
  disabled?: boolean
  className?: string
  autoFocus?: boolean
  trackPosition?: boolean
};

const PartCategoriesSelect: React.FC<Props> = ({ autoFocus, name, disabled, defaultValue, displayValue, required, className, trackPosition }) => {
  const appContext = useAppContext()
  const { partCategories } = appContext

  const sortedCategories = [...partCategories].sort((a, b) => {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })

  const ref = useRef<HTMLButtonElement>(null)
  const optionsStyle: CSSProperties = {}
  if (trackPosition) {
    const boundingRect = useBoundingRect(ref)
    if (boundingRect) {
      if (boundingRect.top > (window.innerHeight / 2)) {
        optionsStyle.bottom = (-boundingRect.top) + 4
      }
      else {
        optionsStyle.top = boundingRect.top + boundingRect.height
      }
    }
  }

  return <Controller
    name={name}
    defaultValue={defaultValue}
    rules={{ required }}
    render={({ field, fieldState }) => (
      <ListBox.ListBox {...field} as='div' className='text-xs'>
        <ListBox.UncontrolledButton
          ref={ref}
          autoFocus={autoFocus}
          disabled={disabled}
          className={className + (fieldState.invalid && ' bg-red-200' || '')} displayFunction={v => {
          return partCategories.find(c => c.id === v.value)?.name || displayValue
        }} />
        <ListBox.Options align='left' style={optionsStyle}>
          {sortedCategories.map((category) => (
            <ListBox.Option key={category.id} value={category.id} display={category.name} />
          ))}
        </ListBox.Options>
      </ListBox.ListBox>
    )}
  />
}

export default PartCategoriesSelect
