import { PrivateSet, Set, Router, Route, navigate, routes, Redirect, useParams } from '@redwoodjs/router'

import AppLayout, { FullWidthLayout } from 'src/layouts/AppLayout'
import OrgSettingsLayout from 'src/layouts/OrgSettingsLayout'

import { useAuth } from './auth'
import { useQuery } from '@apollo/client'
import { useEffect } from 'react'

const RedirectRoot: React.FC = () => {
  const { data } = useQuery(gql`
    query GetMeQuery {
      me {
        defaultOrgId
      }
    }
  `)
  useEffect(() => {
    if (data?.me.defaultOrgId) {
      navigate(routes.home({ orgId: data?.me.defaultOrgId }), { replace: true })
    }
  }, [data])
  return <></>
}
const RedirectToParts: React.FC = () => {
  const orgId = useParams().orgId!
  return <Redirect to={routes.parts({ orgId })} options={{ replace: true }} />
}

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <Route path="/login" page={LoginPage} name="login" />
      <Route path="/signup" page={LoginPage} name="signup" />
      <PrivateSet unauthenticated='login'>
        <Route path="/" page={RedirectRoot} name="root" />
        <Route path="/superuser/view" page={SuperUserPage} name="superUser" />
        <Route path="/superuser/org/{orgId:String}" page={SuperUserPage} name="superUserOrg" />
        <Set wrap={FullWidthLayout}>
          <Set wrap={OrgSettingsLayout}>
            <Route path="/{orgId:String}/settings" page={OrgSettingsPage} name="orgSettings" />
            <Route path="/{orgId:String}/settings/categories" page={OrgSettingsCategoriesPage} name="orgSettingsCategories" />
            <Route path="/{orgId:string}/settings/distributors" page={OrgSettingsDistributorsPage} name="orgSettingsDistributors" />
            <Route path="/{orgId:string}/settings/lifecycle" page={OrgSettingsLifecyclePage} name="orgSettingsLifecycle" />
          </Set>
          {/* <Route path="/{orgId:String}/parts" page={AllPartsPage} name="parts" /> */}
        </Set>
        <Set wrap={AppLayout}>
          <Route path="/{orgId:String}" page={RedirectToParts} name="home" />

          <Route path="/{orgId:String}/parts" page={PartsPage} name="parts" />
          <Route path="/{orgId:String}/parts/hierarchy" page={PartsHierarchyPage} name="hierarchy" />

          <Route path="/{orgId:String}/notifications" page={NotificationsPage} name="notificatins" />
          <Route path="/{orgId:String}/parts/view/{partNumber:String}" page={PartPage} name="part" />
          <Route path="/{orgId:String}/parts/view/{partNumber:String}/history" page={PartHistoryPage} name="partHistory" />

          {/* The following routes don't work when you reload in dev, but they work on Netlify  */}
          <Route path="/{orgId:String}/parts/view/{partNumber:String}/version/{version:String}" page={PartPage} name="partWithVersion" />
          <Route path="/{orgId:String}/parts/compare/{basePartNumber:String}/{baseVersion:String}/to/{incomingPartNumber:String}/{incomingVersion:String}" page={PartComparePage} name="partCompare" />

          <Route path="/{orgId:String}/change-orders/{state}" page={ChangeOrdersPage} name="changeOrders" />
          <Route path="/{orgId:String}/change-order/create" page={CreateChangeOrderPage} name="changeOrderCreate" />
          <Route path="/{orgId:String}/change-order/{orderNumber:Int}" page={ChangeOrderPage} name="changeOrder" />
          <Route path="/{orgId:String}/change-order/{orderNumber:Int}/{tab:String}" page={ChangeOrderPage} name="changeOrderTab" />
        </Set>
      </PrivateSet>
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
