import {
  UsersIcon,
  FingerPrintIcon,
  TruckIcon,
  QueueListIcon,
} from '@heroicons/react/24/outline';
import { routes, NavLink, useParams } from '@redwoodjs/router'
import { useContext } from 'react'
import AppContext from 'src/lib/appContext';

type OrgSettingLayoutProps = {
  children?: React.ReactNode
}

const OrgSettingLayout = ({ children }: OrgSettingLayoutProps) => {
  const appContext = useContext(AppContext)
  const orgId = useParams().orgId!

  return (
    <div className='flex gap-16  max-w-6xl'>
      <div className='w-64 shrink-0 text-sm flex flex-col gap-3 pb-8 pt-12 pl-10 bg-[#F7F7FF]'>
        <div className='font-medium text-base mb-2'>Organization Settings</div>
        <NavLink
          className='align-middle'
          activeClassName='font-semibold'
          to={routes.orgSettings({orgId})}>
          <div className='flex gap-2'>
            <UsersIcon className='w-4' />
            Teams and Workflow
          </div>
        </NavLink>
        <NavLink
          className='align-middle'
          activeClassName='font-semibold text-gray-800'
          to={routes.orgSettingsCategories({orgId})}>
          <div className='flex gap-2'>
            <FingerPrintIcon className='w-4' />
            Part Categories
          </div>
        </NavLink>
        <NavLink
          className='align-middle'
          activeClassName='font-semibold text-gray-800'
          to={routes.orgSettingsDistributors({orgId})}>
          <div className='flex gap-2'>
            <TruckIcon className='w-4' />
            Distributors
          </div>
        </NavLink>
        {appContext?.me.isSuperUser &&
          <NavLink
            className='align-middle'
            activeClassName='font-semibold text-gray-800'
            to={routes.orgSettingsLifecycle({orgId})}>
            <div className='flex gap-2'>
              <QueueListIcon className='w-4' />
              Lifecycle
            </div>
          </NavLink>
        }
      <div/>
      <div className='fixed bottom-0 top-0 left-0 w-64 bg-[#F7F7FF] -z-50'/></div>
      <div className='w-full pt-12'>
        {children}
      </div>
    </div>
  )
}
export default OrgSettingLayout

