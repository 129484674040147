const ALPHANUMERIC_RADIX = 36 as const;

export type VersionParts = {
  segments: string[],
  mutation?: number
}

export type UIVersionParts = {
  segments: string[],
}

export const versionParts = (version: string): VersionParts | undefined => {
  const [base, mutation, ...invalid] = version.split('~')
  const validMutation = mutation && /^[1-9][0-9]*$/.test(mutation) && Number(mutation)
  if (validMutation === false) {
    return;
  }
  if (invalid?.length) {
    return;
  }
  const parts = uiVersionParts(base);
  if (parts) {
    return {
      segments: parts.segments,
      mutation: validMutation || undefined
    }
  }
}

export const validVersion = (version?: string | null): boolean =>  {
  if (!version) return false
  const parts = uiVersionParts(version)
  if (!parts) return false
  return (parts.segments.length === 3 || parts.segments.length === 2)
}

export const uiVersionParts = (version: string): UIVersionParts | undefined => {
  const dotSeparatedAlphaNumberic = /^[a-zA-Z0-9]+([\.][a-zA-Z0-9]+)*$/;

  if (!dotSeparatedAlphaNumberic.test(version)) {
    return;
  }

  return {
    segments: version.split('.')
  }
}

export const isVersionIncrement = (oldVersion: string, newVersion: string): boolean => {
  const newVersionParts = uiVersionParts(newVersion)
  const oldVersionParts = versionParts(oldVersion)

  if (!newVersionParts || !oldVersionParts) {
    return false;
  }

  for (let i = 0; i < newVersionParts.segments.length; i++) {
    const oldRaw = oldVersionParts.segments[i];

    if (oldRaw === undefined) {
      return true;
    }

    const oldSegment = parseInt(oldRaw, ALPHANUMERIC_RADIX);
    const newSegment = parseInt(newVersionParts.segments[i]!, ALPHANUMERIC_RADIX);

    if (newSegment < oldSegment) {
      return false;
    }

    if (newSegment > oldSegment) {
      return true;
    }
  }

  return false;
}

export const sortVersions = (versions: string[]) => {
  return versions.sort((a, b) => {
    const aParts = a.split('.').map(Number)
    const bParts = b.split('.').map(Number)

    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      const aPart = aParts[i] || 0
      const bPart = bParts[i] || 0

      if (aPart !== bPart) {
        return aPart - bPart
      }
    }

    return 0
  })
}

export const sortInstances = <T extends { version: string }>(versions: T[]): T[] => {
  return versions.sort((a, b) => {
    const aParts = a.version.split('.').map(Number)
    const bParts = b.version.split('.').map(Number)

    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      const aPart = aParts[i] || 0
      const bPart = bParts[i] || 0

      if (aPart !== bPart) {
        return aPart - bPart
      }
    }

    return 0
  })
}
