import { registerFragment } from '@redwoodjs/web/apollo'
export const dependencyDisplayFragment = registerFragment(gql`
  fragment DependencyDisplayFragment on Dependency {
    section
    referenceDesignator
    quantity
    units
    groupId
    toVersionRange
    to {
      proto {
        currentVersionString
        category {
          name
        }
      }
      name
      partNumber
      version
      partNumber
      lifeCycle
      branch
    }
  }
`)

// This is imported in so many places it causes circular dependencies
// so it is abstracted to its own file
export const CHANGE_ORDER_CHANGES_QUERY = gql`
query ChangeOrderChangesQuery ($orderNumber: Int!) {
  currentOrg {
    name
    id
    metadataSchema
    defaultPartVersion
    distributors {
      id
      name
    }
  }
  changeOrder (number: $orderNumber) {
    _id
    state
    number
    name
    description
    reviewers {
      user {
        name
      }
    }
    baseOrphans {
      name
      partNumber
      summary
      version
      lifeCycle
      publishId
      proto {
        currentVersionString
        category {
          name
        }
      }
      dependencies {
        ...DependencyDisplayFragment
      }
      tree {
        ...PartTreeNodeFragment
      }
    }
    includedParts {
      id
      partNumber
      name
      version
      lifeCycle
      summary
      changeMessage
      transitionPlan
      cadRev
      isRoot
      isOffTheShelf
      metadata
      ancestors {
        part {
          partNumber
          lifeCycle
        }
      }
      artifacts {
        id
        filename
        fileId
        file {
          url
          inlineUrl
          contentType
          size
        }
      }
      dependencies {
        ...DependencyDisplayFragment
      }
      sources {
        id
        url
        comment
        distributorId
        distributor {
          id
          name
        }
        priority
        distributorSku
        price
        priceCurrency
        leadTimeDays
        stock
      }
      updateTo {
        partNumber
        name
        isRoot
        isOffTheShelf
        version
        lifeCycle
        summary
        changeMessage
        cadRev
        metadata
        ancestors {
          part {
            partNumber
            lifeCycle
          }
        }
        artifacts {
          id
          filename
          fileId
          file {
            url
            inlineUrl
            contentType
            size
          }
        }
        sources {
          id
          url
          comment
          distributorId
          distributor {
            id
            name
          }
          priority
          distributorSku
          price
          priceCurrency
          leadTimeDays
          stock
        }
        dependencies {
          ...DependencyDisplayFragment
        }
      }
      updateToPublishId
      proto {
        partNumber
        currentVersionString
        category {
          id
          name
        }
        currentPublishId
      }
    }
  }
}
`

export const CHANGE_ORDER_QUERY = gql`
  query FindChangeOrderQuery($orderNumber: Int!) {
    me {
      id
      isSuperUser
    }
    users {
      id
      name
      email
    }
    currentOrg {
      id
      me {
        role
      }
      userGroups {
        id
        name
        members { userId }
      }
      lifeCycleStages {
        key
        name
        color
      }
    }

    changeOrder: changeOrder(number: $orderNumber) {
      ...ChangeOrderReviewersFragment
      _id
      state
      mergeConflicts
      invalidLifeCycle
      highestLifeCycle
      number
      name
      description
      includedPartNumbers
      creator {
        id
      }
      reviewers {
        response
        role
        user {
          id
          name
        }
      }
      workflow {
        groupIds
        rules {
          condition {
            type
            stage
          }
          effect {
            type
            groupId
          }
        }
      }
      reviewerGroups {
        groupId
      }
      log {
        user {
          name
        }
        children {
          action
          partNumber
          payload
        }
        id
        action
        createdAt
        payload
        partNumber
      }
    }
  }
`

export const partTreeNodePartFragment = registerFragment(gql`
  fragment PartTreeNodePartFragment on Part {
    name
    cadRev
    version
    lifeCycle
    summary
    partNumber
    metadata
    sources {
      id
      url
      comment
      distributorId
      distributor {
        id
        name
      }
      priority
      distributorSku
      price
      priceCurrency
      leadTimeDays
      stock
    }
    artifacts {
      id
      fileId
      filename
      file {
        url
        inlineUrl
        contentType
        size
        confirmed
        path
      }
    }
    proto {
      currentVersionString
      category {
        id
        name
      }
    }
  }
`)

export const partTreeNodeFragment = registerFragment(gql`
  fragment PartTreeNodeFragment on PartTreeNode {
    hierarchy
    isTreeRoot
    part {
      ...PartTreeNodePartFragment
    }
    parentToNodeDependency {
      quantity
      units
      toVersionRange
      referenceDesignator
      groupId
    }
  }
`)
