import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from 'headlessuinext'
import { routes, useParams, useLocation, navigate } from '@redwoodjs/router'
import { NavLink, Link } from '@redwoodjs/router'
import { Fragment, useContext, useMemo, useRef, useState } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { useAppContext } from 'src/lib/appContext'
import { useSearchShortcut, ShortcutInfo } from '../KeyShortcuts/KeyShortcuts'
import { MetadataSchemaContext, searchMetadata } from 'src/lib/metadata'
import Fuse from 'fuse.js'
import { SearchCellQuery, SearchCellQueryVariables } from 'types/graphql'
import { CellSuccessProps, CellFailureProps, useMutation } from '@redwoodjs/web'
import keyBy from 'lodash.keyby'
import { usePartProtosCache } from 'src/lib/usePartProtosCache'

export const QUERY = gql`
  query SearchCellQuery {
    me {
      id
      latestPartViews {
        partNumber
      }
    }
  }
`

export const beforeQuery = () => {
  return {
    fetchPolicy: 'network-only', // Forces a fresh fetch on every render
  }
}

export const Loading = ({}) => {
  return <SearchInput latestPartViews={[]} />
}

export const Failure = ({}) => {
  return <SearchInput latestPartViews={[]} />
}


export const Success = (props: CellSuccessProps<SearchCellQuery, SearchCellQueryVariables>) => {
  return <SearchInput latestPartViews={props.me.latestPartViews.map(v => v.partNumber)} />
}

type SearchInputProps = {
  latestPartViews: string[]
}

const SearchInput: React.FC<SearchInputProps> = ({ latestPartViews }) => {
  const orgId = useParams().orgId!
  const { partProtos } = usePartProtosCache()

  const [search, setSearch] = useState('')
  const searchInputRef = useRef<HTMLInputElement>(null)

  useSearchShortcut(() => {
    searchInputRef?.current?.focus()
  })

  const allParts = partProtos || []

  const metadataSchema = useContext(MetadataSchemaContext)
  if (!metadataSchema) return

  const { label, filteredParts } = useMemo(() => {
    const partsByKey = keyBy(allParts, 'partNumber')
    if (!search) {
      const recentlyViewed = latestPartViews.map(partNumber => {
        return partsByKey[partNumber]
      }).filter(Boolean) as (typeof allParts)

      const otherParts = allParts.filter(p => !latestPartViews.includes(p.partNumber) )
      const byLastUpdated = otherParts.sort((a, b) => {
        if (a.updatedAt > b.updatedAt) return 1
        if (a.updatedAt < b.updatedAt) return -1
        return 0
      })
      const latestParts = ([...recentlyViewed, ...byLastUpdated]).slice(0, 10)
      return {
        label: 'Recent Parts',
        filteredParts: latestParts.map(proto => ({
          proto,
          detail: <div className='truncate' key={proto.partNumber}>
              <span className='font-semibold'>#{proto.partNumber}</span> {proto.currentVersion.name}
            </div>
        }))
      }
    }

    const matchDetail = search.length > 2

    const filteredParts = allParts.map(proto => {
      const query = search.toLowerCase()
      const name = proto.currentVersion.name || ''
      const pnMatch = getFuseTotalScore(search, proto.partNumber)
      const nameMatch = getFuseTotalScore(search, name)

      const metadataMatches = []
      let maxMetadataScore = 0

      const metadataResults = searchMetadata({
        schema: metadataSchema,
        checkValue(val) {
          const score = getFuseTotalScore(search, val)
          if (score > 0) {
            maxMetadataScore = Math.max(maxMetadataScore, score) // Track max score
            return true
          }
          return false
        },
        metadata: proto.currentVersion.metadata
      })

      for (const m of metadataResults) {
        metadataMatches.push(
          <div key={m.key}>
            <span>{m.displayName}</span>: <HighlightMatches key={m.key} truncate={false} searchTerm={query} text={m.entry} />
          </div>
        )
      }

      const sourceMatches = []
      let maxSourceScore = 0

      for (const source of proto.currentVersion.sources) {
        if (source.url) {
          const score = getFuseTotalScore(search, source.url)
          if (score > 0) {
            sourceMatches.push({ key: 'URL', value: source.url, score })
            maxSourceScore = Math.max(maxSourceScore, score)
          }
        }
        if (source.distributorSku) {
          const score = getFuseTotalScore(search, source.distributorSku)
          if (score > 0) {
            sourceMatches.push({ key: 'SKU', value: source.distributorSku, score })
            maxSourceScore = Math.max(maxSourceScore, score)
          }
        }
        if (source.comment) {
          const score = getFuseTotalScore(search, source.comment)
          if (score > 0) {
            sourceMatches.push({ key: 'Comment', value: source.comment, score })
            maxSourceScore = Math.max(maxSourceScore, score)
          }
        }
      }

      const sourceMatchText = sourceMatches.map((match, i) => {
        return <div key={i}>
          <span>Source {match.key}: </span><HighlightMatches searchTerm={query} text={match.value} />
        </div>
      })

      const artifactMatches = []
      let maxartifactScore = 0

      for (const artifact of proto.currentVersion.artifacts) {
        if (artifact.filename) {
          const score = getFuseTotalScore(search, artifact.filename)
          if (score > 0) {
            artifactMatches.push(artifact.filename)
            maxartifactScore = Math.max(maxartifactScore, score)
          }
        }
      }

      const artifactMatchText = artifactMatches.map(match => {
        return <div key={match}>
          <span>Artifact: </span><HighlightMatches searchTerm={query} text={match} />
        </div>
      })

      const summary = proto.currentVersion.summary || ''
      const summaryMatch = matchDetail ? getFuseTotalScore(search, summary) : 0

      const title = `#${proto.partNumber} ${proto.currentVersion.name}`
      const detail = <div className='flex flex-col gap-2'>
        <div className='truncate'>
          <HighlightMatches searchTerm={query} text={title} truncate={false} />
        </div>
        {
          metadataMatches
        }
        {
          artifactMatchText
        }
        {
          sourceMatchText
        }
        {
          summaryMatch ? <div>
          <HighlightMatches searchTerm={query} text={summary} />
        </div> : null
        }
      </div>

      // massively prefers exact matches
      const calculateScore = (input: number, multiplier: number = 1) => {
        if (input > 0.9) return multiplier * 10
        return input * multiplier
      }

      const totalScore = calculateScore(pnMatch, 5) +
        calculateScore(nameMatch, 1.2) +
        calculateScore(maxMetadataScore) +
        calculateScore(summaryMatch) +
        calculateScore(maxSourceScore) +
        calculateScore(maxartifactScore)

      return {
        proto,
        score: totalScore,
        detail
      }
    })
    .filter(m => m.score > 0)
    .sort((a, b) => {
      if (a.score > b.score) return -1
      if (a.score < b.score) return 1
      return 0
    })

    return {
      label: 'Search Results',
      filteredParts: filteredParts.slice(0, 50)
    }
  }, [search, allParts])

  const handlePartSelect = (partNumber: string) => {
    if (partNumber) {
      navigate(routes.part({ orgId, partNumber }))
    }
  }

  const handleClose = () => {
    searchInputRef.current?.blur()
  }

  return <Combobox
    immediate
    value=''
    as={Fragment}
    onChange={handlePartSelect}
    onClose={handleClose}>
    {({ open }) => {
      return <div
        data-open={open}
        className={`
            relative flex-grow max-w-48
            h-8 text-sm
            transition-all duration-300 data-[open=true]:max-w-full
          `}>
        <div
          data-open={open}
          className={`
                h-8
                absolute z-[90] left-0 right-0 top-0
                transition-all duration-300
                flex items-center
                rounded
              data-[open=true]:bg-white data-[open=true]:text-gray-900`}>
          <ComboboxInput
            ref={searchInputRef}
            value={search || ''}
            placeholder="Search All Parts"
            onChange={e => setSearch(e.target.value)}
            className={`
                  bg-transparent
                  h-full w-full rounded-md border-0 pr-3 pl-10
                  ring-1 ring-inset ring-gray-300 bg-red-200
                  data-[open]:ring-2 data-[open]:ring-inset data-[open]:ring-brand-500
                  placeholder-gray-100 data-[open]:placeholder-gray-900
                `} />
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon className="h-4 w-4" aria-hidden="true" />
          </div>
        </div>
        <ComboboxOptions
          className='bg-white absolute -left-1 -top-1 -right-1 rounded-md z-[80] p-2 pt-12 text-gray-800 shadow-md text-sm'>
          <div className='px-3 py-2 font-bold'>{label}</div>
          <div className='overflow-y-scroll max-h-96'>
            {
              filteredParts.map(match => {
                return <Link key={match.proto.partNumber} to={routes.part({ orgId, partNumber: match.proto.partNumber })}><ComboboxOption
                  value={match.proto.partNumber}
                  className='data-[focus]:bg-brand-500 data-[focus]:text-white cursor-pointer p-3 flex flex-col gap-2'>
                  {
                    match.detail
                  }

                </ComboboxOption></Link>
              })
            }
            {
              filteredParts.length > 0 ? null : <div className='text-gray-400 italic px-3 pb-3'>No parts found</div>
            }
          </div>
          <div className='px-3 pt-2 border-t border-gray-300 flex gap-2'>
            <ShortcutInfo modifier='cmd' keyButton='K' info='to open search' />
            <ShortcutInfo keyButton='ESC' info='to close' />
          </div>
        </ComboboxOptions>
      </div>
    }}
  </Combobox>
}

interface HighlightMatchesProps {
  text: string;
  searchTerm: string;
  truncate?: boolean; // Whether to truncate context or not
}

export const HighlightMatches: React.FC<HighlightMatchesProps> = ({
  text,
  searchTerm,
  truncate = true,
}) => {
  if (!searchTerm.trim()) {
    return <>{text}</>;
  }
  if (!text.toLowerCase().includes(searchTerm)) return <>{text}</>

  // Escape special characters in the search term for regex
  const escapedTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(`(${escapedTerm})`, 'gi');

  // Find all matches with optional context
  const matches: { before: string; match: string; after: string }[] = [];
  text.replace(regex, (match, _, offset) => {
    if (truncate) {
      const beforeStart = Math.max(0, offset - 50);
      const afterEnd = Math.min(text.length, offset + match.length + 50);

      const before = text.slice(beforeStart, offset).trimStart();
      const after = text.slice(offset + match.length, afterEnd).trimEnd();

      matches.push({ before, match, after });
    } else {
      const before = text.slice(0, offset);
      const after = text.slice(offset + match.length);
      matches.push({ before, match, after });
    }

    return match;
  });

  return (
    <>
      {matches.map((m, index) => (
        <React.Fragment key={index}>
          {index > 0 && truncate && '...'}
          {m.before && (index > 0 ? ' ' : '') + m.before}
          <span className="font-bold">{m.match}</span>
          {m.after}
        </React.Fragment>
      ))}
      {truncate && matches.length < text.length && '...'}
    </>
  );
};

function getFuseTotalScore(searchTerm: string, inputString: string): number {
  // Wrap the input string in an array since Fuse.js expects a list
  const list: string[] = [inputString]

  // Configure Fuse.js
  const options = {
    includeScore: true, // Include scores in the results
    threshold: 0.4,     // Allow any match
    ignoreLocation: true
  }

  // Initialize Fuse with the list
  const fuse = new Fuse(list, options)

  // Search for the term
  const results = fuse.search(searchTerm)

  const maxScore: number = results.reduce((max, result) => {
    if (result.score !== undefined) {
      const invertedScore = 1 - result.score // Invert the score
      return Math.max(max, invertedScore)
    }
    return max
  }, 0)

  return maxScore
}