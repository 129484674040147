//THIS MODULE IS INTENTED TO MIRROR THE WEB ONE

const ALPHANUMERIC_RADIX = 36 as const;

export type VersionParts = {
  segments: string[],
  mutation?: number
}

export type UIVersionParts = {
  segments: string[],
}

export const versionParts = (version: string): VersionParts | undefined => {
  const [base, mutation, ...invalid] = version.split('~')
  const validMutation = mutation && /^[1-9][0-9]*$/.test(mutation) && Number(mutation)
  if (validMutation === false) {
    return;
  }
  if (invalid?.length) {
    return;
  }
  const parts = uiVersionParts(base);
  if (parts) {
    return {
      segments: parts.segments,
      mutation: validMutation || undefined
    }
  }
}

export const validVersion = (version: string): boolean =>  {
  const parts = uiVersionParts(version)
  if (!parts) return false
  return (parts.segments.length === 4 || parts.segments.length === 3 || parts.segments.length === 2)
}

export const uiVersionParts = (version: string): UIVersionParts | undefined => {
  const dotSeparatedAlphaNumberic = /^[a-zA-Z0-9]+([\.][a-zA-Z0-9]+)*$/;

  if (!dotSeparatedAlphaNumberic.test(version)) {
    return;
  }

  return {
    segments: version.split('.')
  }
}

export const isVersionIncrement = (oldVersion: string, newVersion: string): boolean => {
  const newVersionParts = uiVersionParts(newVersion)
  const oldVersionParts = versionParts(oldVersion)

  if (!newVersionParts || !oldVersionParts) {
    return false;
  }

  for (let i = 0; i < newVersionParts.segments.length; i++) {
    const oldRaw = oldVersionParts.segments[i];

    if (oldRaw === undefined) {
      return true;
    }

    const oldSegment = parseInt(oldRaw, ALPHANUMERIC_RADIX);
    const newSegment = parseInt(newVersionParts.segments[i]!, ALPHANUMERIC_RADIX);

    if (newSegment < oldSegment) {
      return false;
    }

    if (newSegment > oldSegment) {
      return true;
    }
  }

  return false;
}

//DEPRECATED
export const createMinorVersionIncrement = (version: string) => {
  return version + '.1'
}

// Takes the rightmost number or character from a . seperated
// string and increments it. For characters, only supports single
// chars less than "z", else it throws.
export const calculateNextVersion = (version: string) => {
  const parts = version.split('.')
  const lastVersion = parts[parts.length - 1]!
  const higherVersion = parts.slice(0, -1)
  if (isNaN(Number(lastVersion))) {
    if (lastVersion.length > 1 || lastVersion.toLowerCase() === 'z') {
      throw new Error('Cannot calculate next version for ' + version)
    }

    const nextLetter = String.fromCharCode(lastVersion.charCodeAt(0) + 1)
    return ([...higherVersion, nextLetter]).join('.')
  }

  return ([...higherVersion, Number(lastVersion) + 1]).join('.')
}

