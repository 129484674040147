import { useState } from 'react';

import type {
  OrgSettingsDistributorsQuery,
  CreateDistributorMutation,
  CreateDistributorMutationVariables,
  EditDistributorMutation,
  EditDistributorMutationVariables,
  DeleteDistributorMutation,
  DeleteDistributorMutationVariables,
} from 'types/graphql'

import { CellSuccessProps, CellFailureProps, useMutation } from '@redwoodjs/web'
import { useForm } from 'react-hook-form'
import kebabCase from 'lodash.kebabcase'

import { reportMutationError } from 'src/lib/reportError'

import * as Form from 'src/components/Form'
import Button from 'src/components/Button';
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import {
  PencilSquareIcon,
} from '@heroicons/react/20/solid';

export const QUERY = gql`
  query OrgSettingsDistributorsQuery {
    currentOrg {
      id
      name
      me {
        role
      }
      distributors {
        id
        name
      }
    }
  }
`

export const Loading = () => <div className='flex justify-center p-2'><LoadingSpinner/></div>

export const Empty = () => <div>Empty</div>

import GenericFailure from '../Failure/Failure'
export const Failure = GenericFailure

type SuccessProps = CellSuccessProps<OrgSettingsDistributorsQuery>

export const Success = ({
  currentOrg,
}: SuccessProps) => {
  const sortedDistributors = [...currentOrg.distributors].sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  })
  return (
    <div className='pb-12 flex flex-col gap-5 mb-12'>
      <div className='bold text-2xl'>
        Distributors
      </div>
      <div className='text-sm -mt-1'>Edit the distributors that can be chosen for off-the-shelf parts.</div>
      <div>
        {sortedDistributors.map(d =>
          <DistributorView key={d.id} distributor={d}/>
        )}
        <DistributorView/>
      </div>
    </div>
  )
}

export const CREATE_DISTRIBUTOR_MUTATION = gql`
mutation CreateDistributorMutation ($input: CreateDistributorInput!) {
  createDistributor(input: $input) {
    id
    name
  }
}
`

export const EDIT_DISTRIBUTOR_MUTATION = gql`
mutation EditDistributorMutation ($input: EditDistributorInput!) {
  editDistributor(input: $input) {
    id
    name
  }
}
`

export const DELETE_DISTRIBUTOR_MUTATION = gql`
mutation DeleteDistributorMutation ($input: DeleteDistributorInput!) {
  deleteDistributor(input: $input)
}
`

type DistributorViewProps = {
  distributor?: SuccessProps['currentOrg']['distributors'][number]
}
const DistributorView = ({distributor: d}: DistributorViewProps) => {
  const [createDistributor, { loading: createLoading, error: createError }] = useMutation<CreateDistributorMutation, CreateDistributorMutationVariables>(CREATE_DISTRIBUTOR_MUTATION)
  const [editDistributor, { loading: editLoading, error: editError }] = useMutation<EditDistributorMutation, EditDistributorMutationVariables>(EDIT_DISTRIBUTOR_MUTATION)
  const [deleteDistributor, { loading: deleteLoading, error: deleteError }] = useMutation<DeleteDistributorMutation, DeleteDistributorMutationVariables>(DELETE_DISTRIBUTOR_MUTATION)

  const loading = createLoading || editLoading || deleteLoading
  const error = createError || editError || deleteError

  type FormValues = { distributorName: string }
  const formMethods = useForm<FormValues>()

  const [editing, setEditing] = useState(false)

  const showEditing = editing || !d

  const handleSave = async (formValues: FormValues) => {
    if (d) {
      const variables = {
        input: {
          id: d.id,
          name: formValues.distributorName
        }
      }
      const { errors } = await editDistributor({
        variables,
        awaitRefetchQueries: true,
        refetchQueries: [{ query: QUERY, variables: {} }],
      })

      if (errors) {
        reportMutationError({
          errors,
          variables,
          message: `Error editing distributor`
        })
      }
      else {
        setEditing(false)
      }
    }
    else {
      const variables = {
        input: {
          id: kebabCase(formValues.distributorName),
          name: formValues.distributorName
        }
      }
      const { errors } = await createDistributor({
        variables,
        awaitRefetchQueries: true,
        refetchQueries: [{ query: QUERY, variables: {} }],
      })

      if (errors) {
        reportMutationError({
          errors,
          variables,
          message: `Error creating distributor`
        })
      }

      else {
        formMethods.reset()
      }
    }
  }

  const handleDelete = async () => {
    const variables = {
      input: {
        id: d!.id
      }
    }
    const { errors } = await deleteDistributor({
      variables,
      awaitRefetchQueries: true,
      refetchQueries: [{ query: QUERY, variables: {} }],
    })

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error deleting distributor`
      })
    }
  }

  return (
    <div className='flex gap-1 max-w-96'>
      {!showEditing ?
        <>
          {d!.name} <div className='ml-auto'>
            <button onClick={() => setEditing(true)}>
              <PencilSquareIcon className='w-4'/>
            </button>
          </div>
        </> :
        <>
          <Form.Form formMethods={formMethods} className='flex flex-col gap-3 grow py-3' onSubmit={handleSave}>
            <Form.TextField name='distributorName' placeholder={d ? d.name : 'New Distributor'}
              defaultValue={d?.name}
              disabled={false} validation={{
                required: { value: true, message: 'Invalid Distributor name' },
                pattern: { value: /^[a-zA-Z1-9\-. ]+$/, message: 'Invalid Distributor name' }
              }}
            />
            <Form.FieldError name="distributorName" className="error-message" />
            <div className='flex'>
              {d &&
                <Button onClick={() => setEditing(false)} size='sm'>Cancel</Button>
              }
              <div className='ml-auto flex gap-1'>
                {d &&
                  <Button variant='red' size='sm' disabled={loading} onClick={handleDelete}>Delete</Button>
                }
                <Button variant='primary' type='submit' size='sm' disabled={loading}>{d ? 'Save' : 'Create'}</Button>
              </div>
            </div>
            <Form.FormError error={error} wrapperClassName="text-white bg-red-500 w-full p-2 my-2"/>
          </Form.Form>
        </>
      }
    </div>
  )
}
