import { useEffect, useState } from 'react'
import { useQuery } from '@redwoodjs/web'
import type {
  PartProtosCacheQuery,
  PartProtosCacheQueryVariables
} from 'types/graphql'
import Sentry from './sentry'
import { simpleHash } from './util'
import { useParams } from '@redwoodjs/router'

import { dependencyDisplayFragment } from 'src/lib/queries'

// force into bundle in case it gets
// used in the query below which it no
// longer is.
dependencyDisplayFragment;

export const PART_PROTOS_CACHE_QUERY = gql`
  query PartProtosCacheQuery($ts: String!) {
    partProtosCache(ts: $ts) {
      lastUpdate
      protos {
        inChangeOrders {
          number
          name
          state
        }
        category {
          id
          name
        }
        updatedAt
        partNumber
        protoUpdatedBy {
          name
        }
        currentVersion {
          name
          summary
          version
          lifeCycle
          metadata
          artifacts {
            filename
          }
          sources {
            url
            comment
            distributorSku
          }
        }
        owner {
          id
          name
        }
      }
    }
  }
`

const CACHE_NAME = 'partProtosCache'
const CACHE_TIMESTAMP_NAME = 'partProtosCacheTimestamp'
const CACHE_KEY_NAME = 'partProtosCacheKey'

// always default in dev env
const SOURCE_VERSION = process.env.SOURCE_VERSION || 'default'

const queryHash = simpleHash(String(PART_PROTOS_CACHE_QUERY))

const getCacheKeyValue = (orgId: string) => {
  return `${SOURCE_VERSION}::${queryHash}::${orgId}`
}

const getCacheFromLocalStorage = (orgId: string): PartProtosCacheQuery['partProtosCache'] | null => {
  const cachedData = localStorage.getItem(CACHE_NAME)
  const lastCacheKey = localStorage.getItem(CACHE_KEY_NAME)
  const nextCacheKey = getCacheKeyValue(orgId)

  // delete the cache due to change in org, query or bundle version
  if (nextCacheKey !== lastCacheKey) {
    localStorage.removeItem(CACHE_NAME)
    localStorage.removeItem(CACHE_TIMESTAMP_NAME)
    localStorage.removeItem(CACHE_KEY_NAME)
    return null
  }

  return cachedData ? JSON.parse(cachedData) : null
}

const setCacheInLocalStorage = (orgId: string, cache: PartProtosCacheQuery['partProtosCache']) => {
  localStorage.setItem(CACHE_NAME, JSON.stringify(cache))
  localStorage.setItem(CACHE_TIMESTAMP_NAME, Date.now().toString())
  localStorage.setItem(CACHE_KEY_NAME, getCacheKeyValue(orgId))

  const event = new Event('partProtosCacheUpdate')
  window.dispatchEvent(event)
}

type RefreshInput = {
  debounce?: boolean
}

const dateTimeToTs = (input?: string | null) => {
  if (!input) return '0'
  return String((new Date(input)).getTime())
}

export const usePartProtosCache = (): {
  partProtos: NonNullable<PartProtosCacheQuery['partProtosCache']>['protos']
  loading: boolean
  refresh: (input?: RefreshInput) => void
} => {
  const orgId = useParams().orgId!
  const [cache, setCache] = useState<PartProtosCacheQuery['partProtosCache'] | null>(getCacheFromLocalStorage(orgId))
  const [loading, setLoading] = useState(false)

  const { refetch } = useQuery<PartProtosCacheQuery, PartProtosCacheQueryVariables>(PART_PROTOS_CACHE_QUERY, {
    variables: { ts: dateTimeToTs(cache?.lastUpdate) }, // Use '0' if no lastUpdate
    skip: true, // Don't run the query automatically
  })

  const refresh = async ({debounce} : RefreshInput = {}) => {
    const lastQueryTimestamp = localStorage.getItem(CACHE_TIMESTAMP_NAME)
    const now = Date.now()

    if (debounce && lastQueryTimestamp && now - parseInt(lastQueryTimestamp, 10) < 60 * 1000) {
      // Skip the query if it was made less than 1 minute ago
      return
    }

    setLoading(true)

    try {
      const { data } = await refetch({ ts: dateTimeToTs(cache?.lastUpdate) })
      if (data?.partProtosCache?.protos) {
        const updatedCache = data.partProtosCache
        setCache(updatedCache)
        setCacheInLocalStorage(orgId, updatedCache)
      }
    } catch (e) {
      Sentry.captureException(e)
    }
    setLoading(false)
  }

  useEffect(() => {
    // Sync cache updates across components
    const handleCacheUpdate = () => {
      const updatedCache = getCacheFromLocalStorage(orgId)
      setCache(updatedCache)
    }

    window.addEventListener('partProtosCacheUpdate', handleCacheUpdate)
    return () => {
      window.removeEventListener('partProtosCacheUpdate', handleCacheUpdate)
    }
  }, [])

  useEffect(() => {
    refresh({ debounce: true })
  }, [])

  return {
    partProtos: cache?.protos,
    loading,
    refresh,
  }
}
