import {
  Organization,
  Maybe,
} from 'types/graphql'
import { useState, useEffect } from 'react'

import { PartDiffSummary } from 'src/components/ChangeOrderChangesCell/calculateAllChanges'
import { resolveMetadata } from 'src/lib/metadata'

import { DocumentTextIcon, ArrowDownTrayIcon } from '@heroicons/react/20/solid'
import { CsvLink, ArtifactsBundle, csvConfig } from 'src/components/PartCell/ExportDialog'
import ExcelJS, { Fill, Borders } from 'exceljs'
import { generateCsv } from "export-to-csv";
import { resolveLifeCycle, LifeCycleStages } from 'src/lib/lifecycle'

import LoadingSpinner from 'src/components/Loading'
import Button from 'src/components/Button'


const Loading = () => <div className='flex justify-center'> <LoadingSpinner/> </div>
type ChangeExportButtonsProps = {
  title: React.ReactNode,
  exportParams: ReturnType<typeof useChangeExport>
  onComplete: () => void
}
export const ChangeExportButtons = ({title, exportParams, onComplete}: ChangeExportButtonsProps) => {
  if (!exportParams) {
    return <Loading />
  }

  const {
    writeXlsx,
    csvBody,
    files,
    fileName,
    xlsxFileName,
    csvFileName,
  } = exportParams

  return (
    <div className='flex flex-col gap-2 p-3'>
      <div className='font-medium mb-5 text-lg text-gray-900'>{title}</div>

      <div className="text-sm text-gray-500">
        Formatted Excel
      </div>
      <button
        className='flex items-center gap-4 my-2 border border-gray-200 rounded-lg px-5 py-5 hover:bg-gray-50 -mx-1'
        onClick={writeXlsx}>
          <DocumentTextIcon className='h-10 w-10 text-green-500'/>
          <div className='flex-1 text-left'>{xlsxFileName}</div>
          <ArrowDownTrayIcon className='h-5 w-5 text-gray-500' />
      </button>

      <div className="text-sm text-gray-500">
        Raw CSV
      </div>
      <CsvLink body={csvBody} fileName={csvFileName}/>

      <div className='flex flex-col gap-1'>
        <div className="text-sm text-gray-500">
          Bundle (with Artifacts)
        </div>
        <ArtifactsBundle files={files} name={`${fileName}.zip`} align='left' />
      </div>

      <div className='flex gap-2 mt-4'>
        <Button type='button' onClick={() => onComplete()} >Cancel</Button>
      </div>
    </div>
  )
}

type ExportChangesProps = {
  table: Row[],
  parts: PartDiffSummary[],
  fileName: string,
  dropPartNumber?: boolean
}
export const useChangeExport = ({table: allRows, parts, fileName, dropPartNumber, }: ExportChangesProps) => {
  if (dropPartNumber) {
    allRows = allRows.map(row => ({
      ...row,
      cells: row.cells.slice(1)
    }))
  }
  const [headers, ...table] = allRows
  const xlsxFileName = fileName + '.xlsx'
  const csvFileName = fileName + '.csv'

  const [sheet, setSheet] = useState<Blob | undefined>()

  const createSheet = async () => {
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Changes');

    ws.columns = [
      { header: getValue(headers!.cells[0]), width: 30 },
      { header: getValue(headers!.cells[1]), width: 30 },
      { header: getValue(headers!.cells[2]), width: 30 },
      { header: getValue(headers!.cells[3]), width: 30 },
    ].concat(dropPartNumber ?
      [] :
      [{ header: getValue(headers!.cells[4]), width: 30 }]
    );
    ws.getCell('A1').font = { bold: true }
    ws.getCell('B1').font = { bold: true }
    ws.getCell('C1').font = { bold: true }
    ws.getCell('D1').font = { bold: true }
    if (!dropPartNumber) {
      ws.getCell('E1').font = { bold: true }
    }

    let idx = 2;
    for (const row of table) {
      const rowFill = getStyle(row)
      const border = getBorders(row)

      let cell: Cell
      let exCell: ExcelJS.Cell
      let fill: Fill | undefined

      cell = row.cells[0]
      exCell = ws.getCell('A' + idx)
      exCell.value = getValue(cell)
      fill = getStyle(cell)
      if (rowFill) exCell.fill = rowFill;
      else if (fill) exCell.fill = fill;
      if (border) exCell.border = border;

      cell = row.cells[1]
      exCell = ws.getCell('B' + idx)
      exCell.value = getValue(cell)
      fill = getStyle(cell)
      if (rowFill) exCell.fill = rowFill;
      else if (fill) exCell.fill = fill;
      if (border) exCell.border = border;

      cell = row.cells[2]
      exCell = ws.getCell('C' + idx)
      exCell.value = getValue(cell)
      fill = getStyle(cell)
      if (rowFill) exCell.fill = rowFill;
      else if (fill) exCell.fill = fill;
      if (border) exCell.border = border;

      cell = row.cells[3]
      exCell = ws.getCell('D' + idx)
      exCell.value = getValue(cell)
      fill = getStyle(cell)
      if (rowFill) exCell.fill = rowFill;
      else if (fill) exCell.fill = fill;
      if (border) exCell.border = border;

      if (!dropPartNumber){
        cell = row.cells[4]
        exCell = ws.getCell('E' + idx)
        exCell.value = getValue(cell)
        fill = getStyle(cell)
        if (rowFill) exCell.fill = rowFill;
        else if (fill) exCell.fill = fill;
        if (border) exCell.border = border;
      }

      idx++;
    }

    const buffer = await wb.xlsx.writeBuffer()

    return new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  }

  useEffect(() => {
    (async () => setSheet(await createSheet()))()
  }, [])


  if (!sheet) {
    return null
  }

  const writeXlsx = async () => {
    let link = document.createElement('a');
    link.href = URL.createObjectURL(sheet);
    link.download = xlsxFileName
    link.click();
    URL.revokeObjectURL(link.href);
  }

  const csvBody = table.map(row => {
    const r = ({
      [getValue(headers!.cells[0])!]: getValue(row.cells[0]),
      [getValue(headers!.cells[1])!]: getValue(row.cells[1]),
      [getValue(headers!.cells[2])!]: getValue(row.cells[2]),
      [getValue(headers!.cells[3])!]: getValue(row.cells[3]),
    })
    if (!dropPartNumber) {
      r[getValue(headers!.cells[4])!] = getValue(row.cells[4])
    }
    return r;
  })

  const files = ([
    {
      sourceContent: sheet,
      destination: xlsxFileName,
    },
    {
      sourceContent: String(generateCsv(csvConfig)(csvBody)),
      destination: csvFileName,
    },
  ] as ({source: string, destination: string} | {sourceContent: string, destination: string})[] ).concat(parts
    .flatMap(p =>
      p.fields.artifacts?.change
        ?.filter(c => c.changeType !== 'nothing')
        .flatMap(c =>
          c.changeType === 'deletion' ? [
            {
              source: c.artifact.file.url,
              destination: 'previousArtifacts/' + c.artifact.filename
            }
          ] :
          c.changeType === 'addition' ? [
            {
              source: c.artifact.file.url,
              destination: 'updatedArtifacts/' + c.artifact.filename
            }
          ] : [
            {
              source: p.headPart?.artifacts.find(a => a.filename === c.artifact.filename)?.file.url!,
              destination: 'previousArtifacts/' + c.artifact.filename
            },
            {
              source: c.artifact.file.url,
              destination: 'updatedArtifacts/' + c.artifact.filename
            }
          ]
        )
      ?? (p.type === 'create' ? p.incomingPart.artifacts.map(a => ({
        source: a.file.url,
        destination: 'updatedArtifacts/' + a.filename
      })) : [])
  ))

  return ({
    writeXlsx,
    csvBody,
    files,
    fileName,
    xlsxFileName,
    csvFileName,
  })
}

type Cell = {
  value?: string | null
  style: 'addition' | 'deletion' | 'change' | 'disabled' | 'header'
} | string | null | undefined
type Row = {
  cells: Cell[]
  style?: 'addition' | 'deletion'
  borderTop?: boolean
}

const getValue = (c: Cell): string | undefined => {
  if (c) {
    if (typeof c === 'string') return c;
    else return c.value || undefined
  }
  else {
    return
  }
}

const fillBase: Fill = {
  type: 'pattern',
  pattern: 'solid'
}
const getStyle = (c: Cell | Row): undefined | Fill => {
  if (c && typeof c !== 'string' && c.style) {
    if (c.style === 'addition') {
      return { ...fillBase, fgColor: { argb: 'FFBBF7D0' } }
    }
    else if (c.style === 'change') {
      return { ...fillBase, fgColor: { argb: 'FFFEF08A' } }
    }
    else if (c.style === 'deletion') {
      return { ...fillBase, fgColor: { argb: 'FFFECACA' } }
    }
  }
  return;
}
const getBorders = (r: Row): undefined | Partial<Borders> => {
  if (r.borderTop) {
    return {
      top: {
        style: 'thick',
        color: { argb:'FF000000' }
      }
    }
  }
  else return undefined;
}

type StyleF = (v?: string | null) => Cell
const changedCell = (value?: string | null): Cell => ({style: 'change', value})
const addedCell = (value?: string | null): Cell => ({style: 'addition', value})
const deletedCell = (value?: string | null): Cell => ({style: 'deletion', value})
const headerCell = (value?: string | null): Cell => ({style: 'header', value})

export type TabularizedChangesProps = {
  lifeCycleStages: LifeCycleStages | undefined,
  currentOrg: Pick<Organization, 'metadataSchema'>,
  parts: PartDiffSummary[]
  leftColumnName?: string
  rightColumnName?: string
}

export const tabularizeChanges = ({leftColumnName = 'Before', rightColumnName = 'After', ...props}: TabularizedChangesProps) => {
  const { parts, currentOrg, lifeCycleStages } = props;
  const partHeaders: Row = { cells: ['Part Number', 'Changed', 'Field', leftColumnName, rightColumnName].map(headerCell) }
  const mapped = parts.flatMap((p, idx) => {
    type Fields = (typeof p)['fields']
    const isCreate = p.type === 'create';
    const hasChange = (field: keyof (typeof p)['fields']) => {
      return isCreate || p.fields[field].hasChange
    }
    const rowStyle: Row['style'] = isCreate ? 'addition' : undefined;
    //const disabledCell: Cell = { style: 'disabled' }

    const pn = '#' + p.proto.partNumber;

    const rows: Cell[][] = [];

    const pushMaybe = (...input: (false | Cell[])[]) => {
      for (const cell of input) {
        if (cell) {
          rows.push(cell)
        }
      }
    }

    type KeysWithStrings<T extends object> = {
      [K in keyof T]-?: T[K] extends Maybe<string> | undefined ? K : never
    }[keyof T];

    type StringFields = KeysWithStrings<NonNullable<typeof p.headPart>>

    const TRUE = "TRUE"
    const FALSE = isCreate ? "TRUE" : "FALSE"

    const row = (condition: any, cells: (string | null | undefined)[], styleF: StyleF = (v?: string | null) => v) => {
      const [pn, ...tail] = cells
      const last = tail.pop()
      return condition ?
        [pn, TRUE, ...tail, styleF(last)] :
        [pn, FALSE, ...tail, last]
    }

    const changeRow = (condtition: boolean, label: string, field: StringFields) =>
      row(condtition, [pn, label, p.headPart?.[field], p.incomingPart?.[field]], changedCell)

    pushMaybe(
      //Boolean(p.incomingPart.changeMessage) && [pn, 'Change Message', null, addedCell(p.incomingPart.changeMessage)],
      changeRow(p.headPart?.version !== p.incomingPart.version, 'Version', 'version'),
      changeRow(hasChange('name'), 'Name', 'name'),
      row(Boolean(p.incomingPart.changeMessage), [pn, 'Change Message', null, p.incomingPart.changeMessage], addedCell),
      row(Boolean(p.incomingPart.transitionPlan), [pn, 'Transition Plan', null, p.incomingPart.transitionPlan], addedCell),
      changeRow(hasChange('cadRev'), 'Cad Rev', 'cadRev'),
      row(
        Boolean(hasChange('lifeCycle') && lifeCycleStages?.length),
        [pn, 'Lifecycle',
          isCreate ? '' :
            resolveLifeCycle(lifeCycleStages, p.headPart?.lifeCycle).name ?? '',
          resolveLifeCycle(lifeCycleStages, p.incomingPart?.lifeCycle).name ?? '',
        ],
        changedCell
      ),
      changeRow(hasChange('summary'), 'Summary', 'summary'),
    )

    const metaChange: Fields['metadata']['change'] = p.fields.metadata.change || resolveMetadata(currentOrg.metadataSchema, p.incomingPart.metadata as any)
    if (metaChange) {
      for (const m of metaChange) {
        const headMeta = p.headPart?.metadata as Record<string, any> | undefined
        const e = m.entry;
        const { changeType } = m

        const styledCell = !changeType ? ((v: any) => v) : changeType === 'Change' ? changedCell : changeType === 'Remove' ? deletedCell : addedCell

        let oldValue: string, newValue: string;
        if (typeof e === 'object' && 'unit' in e) {
          const headEntry  = headMeta?.[m.key]
          oldValue = headEntry ? (headEntry.value.toString() + ' ' + headEntry.unit) : ''
          newValue = e.value.toString() + ' ' + e.unit
        }
        else {
          oldValue = headMeta?.[m.key]?.toString()
          newValue = e.toString()
        }

        if (changeType === 'Remove') {
          newValue = ''
        }

        rows.push([pn, changeType ? TRUE : FALSE, `${m.displayName}`,
          oldValue, styledCell(newValue)])
      }
    }

    let sourceChanges = p.fields.sources.change;

    const allNew = sourceChanges.length === 0;
    if (isCreate || allNew) sourceChanges = p.incomingPart.sources;
    for (const s of sourceChanges) {
      const { priority } = s;
      const sourceRenderIdx = priority + 1
      const headSource = p.headPart?.sources[priority]
      const incomingSource = p.incomingPart.sources[priority]

      const renderCurrency = (source: typeof incomingSource) => source?.price ?
        (source.priceCurrency ? source.priceCurrency + ' ' : '') + source.price.toString() : ''

      if (s.wholeSource || allNew) {
        if (s.wholeSource === 'removed') {
          pushMaybe(
            row(
              headSource?.distributor?.name,
              [pn, `[Src ${sourceRenderIdx}] Distributor`,
                headSource?.distributor?.name,
                ''
              ],
              deletedCell
            ),
            row(
              headSource?.distributor?.name,
              [pn, `[Src ${sourceRenderIdx}] Distributor`,
                headSource?.distributor?.name,
                ''
              ],
              deletedCell
            ),
            row(
              headSource?.distributorSku,
              [pn, `[Src ${sourceRenderIdx}] Distributor-Sku`,
                headSource?.distributorSku,
                ''
              ],
              deletedCell
            ),
            row(
              headSource?.comment,
              [pn, `[Src ${sourceRenderIdx}] Comment`,
                headSource?.comment,
                ''
              ],
              deletedCell
            ),
            row(
              typeof headSource?.leadTimeDays === 'number',
              [pn, `[Src ${sourceRenderIdx}] Lead-Time-Days`,
                headSource?.leadTimeDays?.toString(),
                ''
              ],
              deletedCell
            ),
            row(
              typeof headSource?.price === 'number',
              [pn, `[Src ${sourceRenderIdx}] Price`,
                renderCurrency(headSource),
                ''
              ],
              deletedCell
            ),
            row(
              typeof headSource?.stock === 'number',
              [pn, `[Src ${sourceRenderIdx}] Stock`,
                headSource?.stock?.toString(),
                ''
              ],
              deletedCell
            ),
            row(
              headSource?.url,
              [pn, `[Src ${sourceRenderIdx}] Url`,
                headSource?.url,
                ''
              ],
              deletedCell
            ),
          )
        }
        else if (s.wholeSource === 'added' || allNew) {
          pushMaybe(
            row(
              incomingSource?.distributor?.name,
              [pn, `[Src ${sourceRenderIdx}] Distributor`, null,
                incomingSource?.distributor?.name
              ],
              addedCell
            ),
            row(
              incomingSource?.distributorSku,
              [pn, `[Src ${sourceRenderIdx}] Distributor-Sku`, null,
                incomingSource?.distributorSku
              ],
              addedCell
            ),
            row(
              incomingSource?.comment,
              [pn, `[Src ${sourceRenderIdx}] Comment`, null,
                incomingSource?.comment
              ],
              addedCell
            ),
            row(
              typeof incomingSource?.leadTimeDays === 'number',
              [pn, `[Src ${sourceRenderIdx}] Lead-Time-Days`, null,
                incomingSource?.leadTimeDays?.toString()
              ],
              addedCell
            ),
            row(
              typeof incomingSource?.price === 'number',
              [pn, `[Src ${sourceRenderIdx}] Price`, null,
                renderCurrency(incomingSource)
              ],
              addedCell
            ),
            row(
              typeof incomingSource?.stock === 'number',
              [pn, `[Src ${sourceRenderIdx}] Stock`, null,
                incomingSource?.stock?.toString()
              ],
              addedCell
            ),
            row(
              incomingSource?.url,
              [pn, `[Src ${sourceRenderIdx}] Url`, null,
                incomingSource?.url
              ],
              addedCell
            )
          )
        }
      }
      else {
        const styledCell = (c: NonNullable<(typeof s)['fields']>['comment'] | undefined) =>
          c === 'added' ? addedCell : c === 'removed' ? deletedCell : changedCell
        pushMaybe(
          row(
            s.fields?.distributorId,
            [pn, `[Src ${sourceRenderIdx}] Distributor`,
              headSource?.distributor?.name,
              incomingSource?.distributor?.name
            ],
            styledCell(s.fields?.distributorId)
          ),
          row(
            s.fields?.distributorSku,
            [pn, `[Src ${sourceRenderIdx}] Distributor-Sku`,
              headSource?.distributorSku,
              incomingSource?.distributorSku
            ],
            styledCell(s.fields?.distributorSku)
          ),
          row(
            s.fields?.comment,
            [pn, `[Src ${sourceRenderIdx}] Comment`,
              headSource?.comment,
              incomingSource?.comment
            ],
            styledCell(s.fields?.comment)
          ),
          row(
            s.fields?.leadTimeDays,
            [pn, `[Src ${sourceRenderIdx}] Lead-Time-Days`,
              headSource?.leadTimeDays?.toString(),
              incomingSource?.leadTimeDays?.toString()
            ],
            styledCell(s.fields?.leadTimeDays)
          ),
          row(
            s.fields?.price,
            [pn, `[Src ${sourceRenderIdx}] Price`,
              headSource ? renderCurrency(headSource) : '',
              incomingSource ? renderCurrency(incomingSource) : ''
            ],
            styledCell(s.fields?.price)
          ),
          row(
            s.fields?.stock,
            [pn, `[Src ${sourceRenderIdx}] Stock`,
              headSource?.stock?.toString(),
              incomingSource?.stock?.toString()
            ],
            styledCell(s.fields?.stock)
          ),
          row(
            s.fields?.url,
            [pn, `[Src ${sourceRenderIdx}] Url`,
              headSource?.url,
              incomingSource?.url
            ],
            styledCell(s.fields?.url)
          )
        )
      }
    }

    let artifactChanges = p.fields.artifacts.change;
    if (isCreate || !artifactChanges) {
      artifactChanges = p.incomingPart.artifacts.map((a, i) => ({
        changeType: 'addition',
        artifact: a,
        concreteIndex: i,
      }))
    }

    let aIdx = -1;
    for (const c of artifactChanges) {
      aIdx++

      const { changeType } = c

      const styledCell = changeType === 'nothing' ? (a?: string) => a : changeType === 'edit' ? changedCell :
        changeType === 'deletion' ? deletedCell : addedCell

      const showBytes = (size: Maybe<number> | undefined): string =>
        (changeType === 'edit' && size) ? ` (${size} bytes)` : ''


      const head = p.headPart?.artifacts[aIdx]
      const first = (head?.filename ?? '') + showBytes(head?.file.size)
      const second = changeType === 'deletion' ? undefined :
        c.artifact.filename + showBytes(c.artifact.file.size)

      pushMaybe(
        [pn, changeType === 'nothing' ? FALSE : TRUE, `[Artifact ${aIdx + 1}]`,
          first,
          styledCell(second)]
      )
    }

    type Dep = { to: { version: string }, toVersionRange: string }
    const renderVersion = (d: Dep) =>
      `${d.to.version} (${d.toVersionRange === '*' ? 'always update' : 'pinned'})`

    let depChanges = p.fields.dependencies.change
    if (isCreate) {
      depChanges = p.incomingPart.dependencies.map(d => ({
        partNumber: d.to.partNumber,
        wholeDependency: 'added'
      }))
    }
    for (const d of depChanges) {
      if (d.wholeDependency) {
        if (d.wholeDependency === 'added') {
          const newDep = p.incomingPart.dependencies.find(dep => dep.to.partNumber === d.partNumber)!
          pushMaybe(
            [pn, TRUE, `[Dep] #${d.partNumber} Version`, null, addedCell(renderVersion(newDep))],
            [pn, TRUE, `[Dep] #${d.partNumber} Quantity`, null, addedCell(newDep.quantity.toString() + ' ' + newDep.units)],
            !!newDep.referenceDesignator && row(
              !!newDep.referenceDesignator,
              [pn, `[Dep] #${d.partNumber} Reference`, null, newDep.referenceDesignator],
              addedCell
            ),
            !!newDep.groupId && row(
              !!newDep.groupId,
              [pn, `[Dep] #${d.partNumber} Swappable Group Name`, null, newDep.groupId],
              addedCell
            )
          )
        }
        else {
          const oldDep = p.headPart?.dependencies.find(dep => dep.to.partNumber === d.partNumber)!

          pushMaybe(
            [pn, TRUE, `[Dep] #${d.partNumber} Version`, renderVersion(oldDep), deletedCell('')],
            [pn, TRUE, `[Dep] #${d.partNumber} Quantity`, oldDep.quantity.toString() + ' ' + oldDep.units, deletedCell('')],
            !!oldDep.referenceDesignator && row(
              !!oldDep.referenceDesignator,
              [pn, `[Dep] #${d.partNumber} Reference`, oldDep.referenceDesignator, ''],
              deletedCell
            ),
            !!oldDep.groupId && row(
              !!oldDep.groupId,
              [pn, `[Dep] #${d.partNumber} Swappable Group Name`, oldDep.groupId, ''],
              deletedCell
            )
          )
        }
      }
      else {
        const oldDep = p.headPart?.dependencies.find(dep => dep.to.partNumber === d.partNumber)!
        const newDep = p.incomingPart.dependencies.find(dep => dep.to.partNumber === d.partNumber)!
        pushMaybe(
          row(
            d.version,
            [pn, `[Dep] #${d.partNumber} Version`, renderVersion(oldDep), renderVersion(newDep)],
            changedCell
          ),
          row(
            d.quantity,
            [pn, `[Dep] #${d.partNumber} Quantity`, oldDep.quantity.toString() + ' ' + oldDep.units, newDep.quantity.toString() + ' ' + newDep.units],
            changedCell
          ),
          (!!oldDep.referenceDesignator || !!newDep.referenceDesignator) && row(
            d.referenceDesignator,
            [pn, `[Dep] #${d.partNumber} Reference`, oldDep.referenceDesignator, newDep.referenceDesignator],
            changedCell
          ),
          (!!oldDep.groupId || !!newDep.groupId) && row(
            d.groupChange,
            [pn, `[Dep] #${d.partNumber} Swappable Group Name`, oldDep.groupId, newDep.groupId],
            changedCell
          )
        )
      }
    }

    const makeRow = (cells: Cell[], rowIdx: number) => {
      const borderTop = rowIdx === 0 && idx !== 0;
      return { style: rowStyle, cells, borderTop }
    }

    return rows.map(makeRow) as Row[]
  })
  return [partHeaders, ...mapped];
}

/*
export const ChangeOrderTable = (props: {table: ReturnType<typeof tabularizeChanges>}) => {
  const getCellStyle = (row: Row, c?: Extract<Cell, { style: string }>) => {
    if (row.style && c?.style !== 'disabled') {
      if (row.style === 'addition') {
        return 'bg-green-200'
      }
      else if (row.style === 'deletion') {
        return 'bg-red-200'
      }
    }
    if (c?.style) {
      return (
        c.style === 'addition' ? 'bg-green-200' :
        c.style === 'deletion' ? 'bg-red-200' :
        c.style === 'change' ? 'bg-yellow-200' :
        c.style === 'disabled' ? 'bg-gray-50' :
        c.style === 'header' ? 'font-semibold' :
        ''
      )
    }
    return ''
  }

  return <>
    <div className='flex flex-col overflow-y-scroll h-[700px]'>
      {props.table.map(row =>
        <div className='flex h-8'>
          {row.cells.map(cell => isStyledCell(cell) ?
            <div className={`basis-1/4 p-1 border overflow-hidden ${getCellStyle(row, cell)}`}>{cell.value}</div> :
            <div className={`basis-1/4 p-1 border overflow-hidden ${getCellStyle(row)}`}>
              {cell}
            </div>
          )}
        </div>
      )}
    </div>
  </>
}
//*/
