import {
  RebasePartMutation,
  RebasePartMutationVariables
} from 'types/graphql'
import { CHANGE_ORDER_CHANGES_QUERY, CHANGE_ORDER_QUERY } from 'src/lib/queries'
import { useMutation } from '@redwoodjs/web'
import { reportMutationError } from 'src/lib/reportError'
import type { PartDiffSummary } from './calculateAllChanges'
import { FormError } from 'src/components/Form'

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import Button from '../Button'

export const REBASE_PART_MUTATION = gql`
mutation RebasePartMutation (
  $changeOrderNumber: Int!
  $partNumber: String!
) {
  rebasePart(input: {
    changeOrderNumber: $changeOrderNumber
    partNumber: $partNumber
  }) {
    number
  }
}
`

type MergeConflictProps = {
  changeOrderNumber: RebasePartMutationVariables['changeOrderNumber']
  onComplete: () => void
} & Pick<PartDiffSummary, 'proto' | 'headPart' | 'incomingPart'>
export default ({changeOrderNumber, headPart, incomingPart, proto, onComplete}: MergeConflictProps) => {
  const [mutation, { error, loading }] = useMutation<RebasePartMutation, RebasePartMutationVariables>(REBASE_PART_MUTATION)

  const doMutation = () => handleMutation({ changeOrderNumber, partNumber: proto.partNumber })

  const handleMutation = async (variables: RebasePartMutationVariables) => {
    const mutationResult = await mutation({
      variables,
      refetchQueries: [
        { query: CHANGE_ORDER_QUERY, variables: { orderNumber: variables.changeOrderNumber } },
        { query: CHANGE_ORDER_CHANGES_QUERY, variables: { orderNumber: variables.changeOrderNumber } }
      ],
      awaitRefetchQueries: true
    })

    if (mutationResult.errors) {
      reportMutationError({
        errors: mutationResult.errors,
        variables,
        message: `Error rebasing part ${variables.partNumber} from change order ${variables.changeOrderNumber}`
      })
    }
    else {
      onComplete()
    }

    return mutationResult
  }
  //using single equal to handle nullish
  const hasMergeConflict = incomingPart.updateToPublishId != proto.currentPublishId

  return hasMergeConflict &&
    <div className='mx-4 my-2 -mb-4  p-4 flex flex-col gap-2'>
      <div
        className='flex items-center justify-center gap-4 bg-red-100 rounded-xl p-4 text-xs text-gray-800 border-red-200 border'>
        <ExclamationTriangleIcon className='w-10 mt-0.5 text-red-500' />
        <div className='font-medium'>
          {headPart ?
            'This change is based on an out of date version of the part. To proceed with the change order, the change basis must be updated. After rebasing, you should check the changes again to ensure they are still correct.'
            : 'Another part with the same part number has already been created and this update is now out of date. To proceed with this change, this part must be staged as a change to that part. After rebasing, you should check that the changes are still intended.'
          }
        </div>
        <Button disabled={loading} size='sm' className='ml-auto w-48' onClick={doMutation}>
          Rebase Part
        </Button>
      </div>
      <FormError error={error} wrapperClassName="text-white bg-red-500 w-full p-4 rounded" />
    </div>
}
