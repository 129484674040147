import { createContext, Dispatch, SetStateAction, useEffect, useMemo, useReducer, useRef } from 'react'

import { navigate, routes, useParams } from '@redwoodjs/router'

import {
  HierarchyTreeRootNode,
  Aggregator,
  buildAggregationTree,
  SumAggregator,
  MaxAggregator,
  AndAggregator,
  buildHierarchyTree,
  AggregationRootNode,
  NumericTarget,
  BooleanTarget,
  AggregatorTarget
} from 'src/components/PartCell/aggregation';
import type { ExchangeRates } from 'api/src/lib/exchangeRates'
import { useAppContext } from 'src/lib/appContext'

import type {
  PartTreeNodeFragment,
  AddPartHierarchyToChangeOrderMutation,
  AddPartHierarchyToChangeOrderMutationVariables
} from 'types/graphql'


import { ReactNode, useState } from 'react'
import { AlwaysUpdateIcon, PinnedIcon } from '../VersionRange/VersionRange'
import { IncludedPart } from '../ChangeOrderChangesCell/VersionChangeSummary'
import keyBy from 'lodash.keyby'
import calculateNextVersion from 'src/lib/calculateNextVersion'
import { useMutation } from '@redwoodjs/web'
import { reportMutationError } from 'src/lib/reportError'
import { useMetadataSchema } from 'src/lib/metadata';


interface InputNode extends PartTreeNodeFragment {
  previousVersion?: string
  previousVersionRange?: string
}

export interface ControllerTreeNode extends InputNode {
  versionDisplay: React.ReactNode
}

export type SubjectPart = {
  hierarchy: string
  className?: string
  message?: ReactNode
  hideChildren?: boolean
}

export interface RootInput {
  tree: InputNode[]
  subjectParts?: SubjectPart[]
  // should always be built from rootpart?
  initiallyExpandedNodes: string[] | "all"
}

export type PartHierarchyHeaderColumn = {
  name: string
  width: number
  key: string
  type: 'field' | 'metadata' | 'aggregation'
  align?: 'center' | 'right' | 'left'
}

export const ADD_TO_CHANGE_ORDER_MUTATION = gql`
mutation AddPartHierarchyToChangeOrderMutation ($changeOrderNumber: Int!, $input: [PartDeltaInput!]!) {
  addPartDeltas(changeOrderNumber: $changeOrderNumber, allowDuplicates: true, input: $input) {
    partNumber
  }
}
`

export interface HierarchyControllerInput {
  columns?: PartHierarchyHeaderColumn[]
  roots: RootInput[]
  usageContext?: {
    type: 'ChangeOrder',
    changeOrder: {
      includedParts: IncludedPart[]
    }
  }
  stickyName?: boolean
}

interface ControllerRoot extends RootInput {
  tree: ControllerTreeNode[]
  subjectParts: SubjectPart[]
  selectedNodes: string[]
  setSelectedNodes: ((nodes: string[]) => void)
  hierarchyTree: HierarchyTreeRootNode
  aggregations: AggregationRootNode
  rootNode: InputNode
}

export type ActivatableAggregator = Aggregator & { isActive?: boolean, index: number }
export type EditableAggregator = ActivatableAggregator & { isDirty?: boolean, isNew?: boolean, savedIndex?: number }

export interface HierarchyController extends HierarchyControllerInput {
  roots: ControllerRoot[]
  columns: PartHierarchyHeaderColumn[]
  aggregators: ActivatableAggregator[]
  setAggregators: Dispatch<SetStateAction<ActivatableAggregator[]>>

  targetCurrency?: AggregatorTarget
  setTargetCurrency: Dispatch<SetStateAction<NumericTarget | undefined>>

  savedAggregators: Aggregator[]
  exchangeRates: ExchangeRates

  selectable: boolean
  setSelectable: ((flag: boolean) => void)
  addSelectionToChangeOrder: (changeOrderNumber: number) => Promise<void>
  createChangeOrderWithSelection: () => void

  fullScreen: boolean
  toggleFullscreen: () => void

  selectedAgg: EditableAggregator | null
  setSelectedAgg: (aggIndex?: number | null) => void
  createLocalAggregator: () => void
  toolbarMode: ToolBarMode
  setToolbarMode: (mode: ToolBarMode) => void
}

const baseColumns: PartHierarchyHeaderColumn[] = [{
  name: 'Quantity',
  key: 'quantity',
  type: 'field',
  width: 100,
  align: 'center'
}, {
  name: 'Designator',
  key: 'referenceDesignator',
  type: 'field',
  width: 100,
}, {
  name: 'Version',
  key: 'version',
  type: 'field',
  width: 80,
  align: 'left'
}, {
  name: 'Category',
  key: 'category',
  type: 'field',
  width: 150,
}]

const mergeIntoBaseColumns = (passedColumns?: PartHierarchyHeaderColumn[]): PartHierarchyHeaderColumn[] => {
  return baseColumns.map(c => {
    if (!passedColumns) return c
    const conf = passedColumns.find(conf => conf.type === c.type && conf.key === c.key)
    if (!conf) return c
    return {
      ...c,
      ...conf
    }
  })
}

export type ToolBarMode = 'agg' | null

export const useHierarchyControllerFactory = (properties: HierarchyControllerInput): HierarchyController => {
  const orgId = useParams().orgId!
  const appContext = useAppContext()
  const columns = mergeIntoBaseColumns(properties.columns)

  const { aggregationConfigs } = appContext.currentOrg
  const exchangeRates = appContext.currentOrg.exchangeRates as ExchangeRates

  const metadataSchema = useMetadataSchema()!

  const savedAggregators = useMemo(() =>
    (aggregationConfigs || []).map(c => {
      const reducerInput = {
        metadataSchema,
        name: c.name,
        exchangeRates,
        metadata: c.metadata as NonNullable<typeof c.metadata>,
        sources: c.sources as NonNullable<typeof c.sources>
      }
      if (c.reducer === 'Sum') {
        const targetType = c.targetType as NumericTarget
        return SumAggregator({
          targetType,
          ...reducerInput
        })
      }
      if (c.reducer === 'Max') {
        const targetType = c.targetType as NumericTarget
        return MaxAggregator({
          targetType,
          ...reducerInput
        })
      }
      if (c.reducer === 'And') {
        const targetType = c.targetType as unknown as BooleanTarget
        return AndAggregator({
          targetType,
          ...reducerInput
        })
      }
      throw new Error('No reducer known for ' + c.reducer)
    }
    ) ?? [],
    [aggregationConfigs]
  )

  const [selectMode, setSelectMode] = useState(false)

  type SelectedNodes = Record<number, string[]>
  const [selectedNodesByRoot, setSelectedByRoot] = useState<SelectedNodes>({})

  const [aggregators, setAggregators] = useState<ActivatableAggregator[]>(
    (savedAggregators ?? []).map((a, index) => ({ ...a, index, savedIndex: index, isActive: true }))
  )

  const [targetCurrency, setTargetCurrency] = useState<NumericTarget | undefined>()

  const handleSetSelectNodesMode = (flag: boolean) => {
    if (!flag) {
      setSelectedByRoot({})
      setSelectMode(false)
      return
    }

    const selected: SelectedNodes = {}
    for (const [rootIndex, root] of properties.roots.entries()) {
      selected[rootIndex] = root.tree.map(h => h.hierarchy)
    }
    setSelectedByRoot(selected)
    setSelectMode(true)
  }

  const [addToChangeOrderMutation] = useMutation<
    AddPartHierarchyToChangeOrderMutation,
    AddPartHierarchyToChangeOrderMutationVariables
  >(ADD_TO_CHANGE_ORDER_MUTATION)

  const addToChangeOrder = async (changeOrderNumber: number, input: AddPartHierarchyToChangeOrderMutationVariables['input']) => {
    const variables: AddPartHierarchyToChangeOrderMutationVariables = {
      changeOrderNumber,
      input
    }
    const { errors } = await addToChangeOrderMutation({
      variables: variables,
    })

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error adding part to change order`
      })
      return
    }
  }

  const roots = properties.roots.map((root, rootIndex) => {
    const selectedNodes = selectedNodesByRoot[rootIndex] || []
    const setSelectedNodes = (nodes: string[]) => {
      setSelectedByRoot({
        ...selectedNodesByRoot,
        [rootIndex]: nodes
      })
    }

    const rootNode = root.tree.find(n => n.isTreeRoot)!
    const selectedByLevel = keyBy(selectedNodes)
    const filteredHierarchy = selectMode ? root.tree.filter(n => selectedByLevel[n.hierarchy]) : root.tree
    const hierarchyTree = buildHierarchyTree(rootNode, filteredHierarchy)

    const aggregations = buildAggregationTree(hierarchyTree, aggregators, metadataSchema, { targetOverride: targetCurrency })

    const controllerTree = root.tree.map(node => {
      const { parentToNodeDependency } = node

      // is root of tree
      if (!parentToNodeDependency) {
        const previousRootVersion = node.previousVersion ? <>
          <div>{node.previousVersion}</div>
          <div>→</div>
        </> : null

        const controllerRoot: ControllerTreeNode = {
          ...node,
          versionDisplay: <div className='flex items-center gap-1'>
            {previousRootVersion}
            <div>{node.part.version}</div>
          </div>
        }
        return controllerRoot
      }

      // only show this icon when the version range changes
      const alwaysUpdateIcon = !node.previousVersionRange || node.previousVersionRange === parentToNodeDependency.toVersionRange ? null : <AlwaysUpdateIcon />

      const versionIcon = parentToNodeDependency.toVersionRange === '*' ?
        alwaysUpdateIcon : <PinnedIcon version={node.part.version as string} />

      const versionChange = () => {
        if (!node.previousVersion) return
        if (!node.previousVersionRange) return
        if (!parentToNodeDependency) throw Error('Dependency should be there')
        if (node.previousVersion === node.part.version &&
          node.previousVersionRange === parentToNodeDependency.toVersionRange) return

        const versionIcon = node.previousVersionRange === '*' ?
          alwaysUpdateIcon : <PinnedIcon version={node.previousVersion} />

        return <>
          <div>{node.previousVersion}</div>
          {versionIcon}
          <div>→</div>
        </>
      }

      return {
        ...node,
        part: {
          ...node.part,
        },
        versionDisplay: <div className='flex items-center gap-1'>
          {versionChange()}
          <div>{node.part.version}</div>
          {versionIcon}
        </div>
      }
    })

    return {
      ...root,
      rootNode,
      hierarchyTree,
      stickyName: true,
      tree: controllerTree,
      aggregations,
      selectedNodes: selectedNodes ?? [],
      setSelectedNodes: setSelectedNodes,
      subjectParts: root.subjectParts ?? [],
      rootPart: rootNode,
    }
  })

  const getSelectedUniqueParts = () => {
    let selectPartsByPn: Record<string, PartTreeNodeFragment> = {}
    for (const root of roots) {
      const selectedByLevel = keyBy(root.selectedNodes)
      const filteredHierarchy = selectMode ? root.tree.filter(n => selectedByLevel[n.hierarchy]) : root.tree
      const selected = keyBy(filteredHierarchy, 'part.partNumber')
      selectPartsByPn = {
        ...selectPartsByPn,
        ...selected,
        [root.rootPart.part.partNumber]: root.rootPart
      }
    }
    return selectPartsByPn
  }

  const createChangeOrderWithSelection = () => {
    const selectPartsByPn = getSelectedUniqueParts()
    const opaquePartNumbers = Object.keys(selectPartsByPn).map(pn => btoa(pn)).join(',')

    navigate(routes.changeOrderCreate({ orgId, withPartNumbers: opaquePartNumbers }))
  }

  const addSelectionToChangeOrder = async (changeOrderNumber: number) => {
    const selectPartsByPn = getSelectedUniqueParts()
    const pushes = Object.values(selectPartsByPn).map(node => {
      return {
        type: 'Push' as const,
        partNumber: node.part.partNumber,
        version: calculateNextVersion(node.part.proto.currentVersionString!),
      }
    })

    await addToChangeOrder(changeOrderNumber, pushes)

    navigate(`${routes.changeOrderTab({ orgId, orderNumber: changeOrderNumber, tab: 'changes' })}`)
  }

  const materialsParam = new URLSearchParams(location.search).has('materials')
  const [_, rerender] = useReducer(x => x + 1, 0)

  const setMaterialsParam = (v: string | null) => {
    const searchParams = new URLSearchParams(location.search)
    if (v) { searchParams.set('materials', v) }
    else { searchParams.delete('materials') }
    history.replaceState(null, "", searchParams.size ? `${location.pathname}?${searchParams.toString()}` : location.pathname)
    rerender()
  }

  const fullScreen = Boolean(materialsParam)

  const toggleFullscreen = () => {
    if (materialsParam) return setMaterialsParam(null)
    setMaterialsParam('true')
  }

  const [mode, setToolbarMode] = useState<ToolBarMode>(null)
  const [selectedAggIdx, setSelectedAggIdx] = useState<number | null>(null)

  useEffect(() => {
    if (mode === 'agg' && aggregators.length === 0) {
      createLocalAggregator()
    }
  }, [mode, aggregators])

  const nameSeed = useRef<number>(0)

  const createLocalAggregator = () => {
    const newAgg: EditableAggregator = {
      ...SumAggregator({
        name: `New Aggregator ${++nameSeed.current}`,
        exchangeRates,
        targetType: {
          type: 'Price',
          unit: 'USD'
        },
        metadata: [],
        sources: []
      }),
      isDirty: true,
      isNew: true,
      isActive: true,
      index: aggregators.length,
    }

    if (!fullScreen) toggleFullscreen()
    setToolbarMode('agg')
    setAggregators(aggregators.concat(newAgg))
    setSelectedAggIdx(aggregators.length)
  }

  const getSelectedAgg = () => selectedAggIdx === null ? null
    : aggregators[selectedAggIdx] as EditableAggregator
  const selectedAgg = getSelectedAgg()

  const setSelectedAgg = (aggIndex?: number | null) => {
    setSelectedAggIdx(aggIndex ?? null)
  }

  return Object.seal({
    ...properties,
    roots,
    exchangeRates: exchangeRates as ExchangeRates,
    columns,
    fullScreen,
    toggleFullscreen,
    selectedAgg,
    setSelectedAgg,
    savedAggregators,
    selectable: selectMode,
    setSelectable: handleSetSelectNodesMode,
    createLocalAggregator,
    toolbarMode: fullScreen ? mode : null,
    setToolbarMode,

    aggregators: aggregators,
    setAggregators,
    targetCurrency,
    setTargetCurrency,
    addSelectionToChangeOrder,
    createChangeOrderWithSelection,
  })
}

type HierarchyContextInput = {
  hierarchyController: HierarchyController,
  root: HierarchyController['roots'][number]
} | null
export const HierarchyControllerContext = createContext<HierarchyContextInput>(null)
