
import { Metadata } from '@redwoodjs/web'

// import AllPartsCell from 'src/components/AllPartsCell';

const PartsPage = () => {
  return (
    <>
      <Metadata title="All Parts" description="All Parts page" />
      <div className='w-full pt-6'>
        {/* <AllPartsCell /> */}
      </div>
    </>
  )
}

export default PartsPage
