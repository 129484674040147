import type { QuantityUnit } from 'shared/types'
import type { MapperConfig } from '../mapperConfigs'

import { DocumentIcon } from '@heroicons/react/24/outline'


// Part Number	Variant	Description	Category	Comments	Vendor	Vendor Part Number
export const allPartsConfig: MapperConfig = {
  name: 'All Parts',
  superUserOnly: true,
  rootCategoryId: 'top-level',
  orgIds: ['hextronics', 'hextronics-sandbox', 'hextronics-demo'],
  rootIsTopLevel: true,
  Logo: DocumentIcon,
  importOutputFields: {
    metadata: [
      'cost',
      'operation-sequence'
    ]
  },
  fileToRows: {
    xlsx: {
      enable: true,
      firstRow: 1
    }
  },
  rowsToBom: [{
    type: 'FilterRemove',
    config: {
      conditions: [{
        type: 'empty',
        column: 'part_number',
      }]
    }
  }, {
    type: 'SubassemblyExplode',
    config: [{
      explodeIdentifier: 'subass',
      subassemblyKeyColumn: 'production_bom_no',
    }]
  }],
  standardizeBom: {
    columns: {
      summary: {
        type: 'ignore'
      },
      metadata: {
        'cost': {
          type: 'simple',
          value: '${row.cost.match(/\\d+\\.\\d+/)?.[0] || 0}-USD',
        },
        'operation-sequence': {
          type: 'simple',
          value: '{{row.operation_sequence}}',
        }
      },
      hierarchy: {
        value: '{{row.__hierarchy}}',
        type: 'simple'
      },
      partNumber: [{
        conditions: [{
          type: 'eq',
          column: '__explode_identifier',
          value: 'subass'
        }],
        onMatch: {
          type: 'simple',
          value: '${row.__firstPart.production_bom_no}'
        },
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: '${row.part_number}',
        }
      }],
      categoryId: [{
        conditions: [{
          type: 'eq',
          column: '__explode_identifier',
          value: 'subass'
        }],
        onMatch: {
          type: 'simple',
          value: 'subassembly'
        },
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: "${kebabCase((row.inv_code || 'other'))}",
        }
      }],
      isOffTheShelf: {
        type: 'simple',
        value: 'false'
      },
      name: [{
        conditions: [{
          type: 'eq',
          column: '__explode_identifier',
          value: 'subass'
        }],
        onMatch: {
          type: 'simple',
          value: '${row.__firstPart.top_level_bom_name}'
        },
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: '${row.description}',
        }
      }],
      quantity: [{
        conditions: [{
          type: 'eq',
          column: '__explode_identifier',
          value: 'subass'
        }],
        onMatch: {
          type: 'simple',
          value: '1'
        },
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: '{{row.qty}}',
        }
      }],
      units: {
        value: 'each' as QuantityUnit,
        type: 'quantity'
      }
    }
  }
}