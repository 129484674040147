import { useState, useRef, useLayoutEffect } from 'react';
export const useAnimationFrame = (callback: () => void, dep?: any[]) => {
  const requestRef = React.useRef<number>();

  const animate = () => {
    callback()
    requestRef.current = requestAnimationFrame(animate);
  }

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current!);
  }, dep ?? []);
}

interface BoundingRect {
  top: number;
  left: number;
  right: number;
  bottom: number;
  width: number;
  height: number;
}
export const useBoundingRect = (ref: React.RefObject<HTMLElement>): BoundingRect | null => {
  const [boundingRect, setBoundingRect] = useState<BoundingRect | null>(null);

  const updateBoundingRect = () => {
    if (ref.current) {
      const {top, left, right, bottom, width, height} = ref.current.getBoundingClientRect();
      if (top !== boundingRect?.top
        || left !== boundingRect?.left
        || right !== boundingRect?.right
        || bottom !== boundingRect?.bottom
        || width !== boundingRect?.width
        || height !== boundingRect?.height) {
        setBoundingRect({
          top,
          left,
          right,
          bottom,
          width,
          height,
        });
      }
    }
  };
  useLayoutEffect(() => {
    updateBoundingRect()
  }, []);

  useAnimationFrame(() => {
    updateBoundingRect()
  }, [ref, boundingRect])

  return boundingRect;
}

export const useScrollListener = () => {
  const verticalTracker = useRef<boolean>(hasVerticalScroll())
  const [verticalScrollbarState, setVerticalScrollbarState] = useState(verticalTracker.current)

  const horizontalTracker = useRef<boolean>(hasHorizontalScroll())
  const [horizontalScrollbarState, setHorizontalScrollbarState] = useState(horizontalTracker.current)

  useAnimationFrame(() => {
    const hasVS = hasVerticalScroll()
    if (hasVS !== verticalTracker.current) {
      verticalTracker.current = hasVS
      setVerticalScrollbarState(hasVS)
    }
    const hasHS = hasHorizontalScroll()

    if (hasHS !== horizontalTracker.current) {
      horizontalTracker.current = hasHS
      setHorizontalScrollbarState(hasHS)
    }
  })

  return [horizontalScrollbarState, verticalScrollbarState]
}

const hasHorizontalScroll = () => {
  const root = document.documentElement;
  return root.scrollWidth > root.clientWidth;
}
const hasVerticalScroll = () => {
  const root = document.documentElement;
  return root.scrollHeight > root.clientHeight;
}

let _scrollbarWidth: number = 0;
export function getScrollbarWidth() {
  if (_scrollbarWidth) return _scrollbarWidth
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  //@ts-ignore
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

  // Removing temporary elements from the DOM
  outer.parentNode!.removeChild(outer);

  _scrollbarWidth = scrollbarWidth
  return scrollbarWidth;
}
